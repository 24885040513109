import { RouteRecordRaw } from "vue-router"
import { PATHS } from "@/global/utils/PATHS"

const routes: Array<RouteRecordRaw> = [
  {
    ...PATHS.home,
    component: () => import("@layout/views/HomeView.vue"),
    meta: {
      pageToGoBackwards: null,
    },
  },
  {
    ...PATHS.notFound,
    component: () => import("@layout/views/NotFoundView.vue"),
    meta: {
      pageToGoBackwards: PATHS.home,
    },
  },
]

export default routes
