import { Instance, SnapshotIn, SnapshotOut, types as t } from "mobx-state-tree"
import { GroupReportSubnameModel } from "@survey/models/GroupReportSubname.model"
import { ColumnTypeBaseModel } from "@survey/models/ColumnTypeBase.model"

export const GroupReportHeaderTableModel = ColumnTypeBaseModel.named(
  "GroupReportHeaderTableModel"
).props({
  groupName: t.identifier,
  names: t.maybeNull(t.array(GroupReportSubnameModel)),
})

export type TGroupReportHeaderTableModelModel = Instance<
  typeof GroupReportHeaderTableModel
>
export type TGroupReportHeaderTableModelSnapshotIn = SnapshotIn<
  typeof GroupReportHeaderTableModel
>
export type TGroupReportHeaderTableModelSnapshotOut = SnapshotOut<
  typeof GroupReportHeaderTableModel
>
