<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.99984 13.2501C3.58072 13.2501 2.4165 14.4143 2.4165 15.8334V16.5834H0.916504V15.8334C0.916504 13.5858 2.75229 11.7501 4.99984 11.7501H8.33317C10.5807 11.7501 12.4165 13.5858 12.4165 15.8334V16.5834H10.9165V15.8334C10.9165 14.4143 9.75229 13.2501 8.33317 13.2501H4.99984Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.22937 5.54022C7.31195 4.69815 5.88398 4.70145 5.13283 5.51038C4.34282 6.36116 4.37311 7.66513 5.21616 8.57304C5.96975 9.38459 7.32214 9.38567 8.23956 8.53378C9.07226 7.76057 9.10445 6.42541 8.22937 5.54022ZM9.25659 4.44706C7.83979 3.135 5.44516 2.96961 4.03364 4.4897C2.65699 5.97225 2.79336 8.16828 4.11697 9.59371C5.53006 11.1155 7.84433 10.9477 9.26024 9.63297C10.7566 8.24348 10.7285 5.92158 9.28363 4.47311C9.27928 4.46871 9.27486 4.46435 9.27038 4.46004C9.26583 4.45564 9.26123 4.45131 9.25659 4.44706Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.583 10.9167H15.833C17.6639 10.9167 19.083 12.3359 19.083 14.1667V14.9167H17.583V14.1667C17.583 13.1643 16.8355 12.4167 15.833 12.4167H12.583V10.9167Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.5666 6.12795C15.002 5.63393 14.1355 5.67498 13.6966 6.11383C13.6873 6.12313 13.6778 6.13218 13.668 6.14097C13.2157 6.54811 13.1802 7.39444 13.7238 7.99844C14.1309 8.45082 14.9772 8.4863 15.5812 7.9427C16.0631 7.50906 16.1087 6.68469 15.5666 6.12795ZM16.5794 5.02128C15.4813 4.03801 13.7074 3.99787 12.65 5.03923C11.4519 6.1335 11.6563 7.9435 12.6088 9.00189C13.7017 10.2162 15.522 10.014 16.5847 9.05764C17.7637 7.99655 17.7233 6.16852 16.6183 5.05819C16.6121 5.05184 16.6057 5.04558 16.5992 5.03941C16.5927 5.03323 16.5861 5.02718 16.5794 5.02128Z"
    />
  </svg>
</template>
