import { App } from "vue"
import { PATH_KEY } from "@global/di/keys"

export type RouterPaths = Record<string, { path: string; name: string }>

export const PATHS: RouterPaths = {
  /**
   * Корневые роуты
   */
  home: {
    path: "/",
    name: "home",
  },
  matrixTemplates: {
    path: "/catalog-templates",
    name: "catalog-templates",
  },
  profile: {
    path: "/profile",
    name: "profile",
  },
  tariffs: {
    path: "/tariffs",
    name: "tariffs",
  },
  tariffDetail: {
    path: "/tariffs/detail/:tariffId",
    name: "tariff-detail",
  },
  survey: {
    path: "/survey",
    name: "survey",
  },
  userMatrices: {
    path: "/user-matrices",
    name: "user-matrices",
  },
  matrixUserDetail: {
    path: "/matrix-detail-:matrixId",
    name: "matrix-user-detail",
  },
  notFound: {
    path: "/:pathMatch(.*)*",
    name: "not-found",
  },
  myCompanies: {
    path: "/my-companies",
    name: "my-companies",
  },
  companyDetail: {
    path: "/my-companies/company/:companyId",
    name: "company-detail",
  },
  companyDetailEdit: {
    path: "/my-companies/company/:companyId/edit",
    name: "company-detail-edit",
  },
  register: {
    path: "/register",
    name: "register",
  },
  login: {
    path: "/login",
    name: "login",
  },
  forgotPassword: {
    path: "/forgot-password",
    name: "forgot-password",
  },
  resetPassword: {
    path: "/reset-password",
    name: "reset-password",
  },
  confirmEmail: {
    path: "/confirm-email",
    name: "confirm-email",
  },
  googleAuth: {
    path: "/google-auth",
    name: "google-auth",
  },
  yandexAuth: {
    path: "/yandex-auth",
    name: "yandex-auth",
  },
  mailAuth: {
    path: "/mail-auth",
    name: "mail-auth",
  },
  invitations: {
    path: "/invitations/:invitationToken",
    name: "invitations",
  },
  authFailed: {
    path: "/auth-failed",
    name: "authFailed",
  },
  /**
   * Дочерние роуты. В них путь прописывается без "/" вначале.
   */
  myCompaniesList: {
    path: "list",
    name: "my-companies-list",
  },
  myCompaniesRequests: {
    path: "requests",
    name: "my-companies-requests",
  },
  companyDetailMembers: {
    path: "/my-companies/company/:companyId/members",
    name: "company-detail-members",
  },
  companyDetailRequests: {
    path: "/my-companies/company/:companyId/requests",
    name: "company-detail-requests",
  },
  matrixTemplateList: {
    path: "list",
    name: "matrix-templates-list",
  },
  matrixDetail: {
    path: "matrix-detail-:matrixId",
    name: "matrix-detail",
  },
  competenceDetail: {
    path: "competence-detail--:matrixId:--:competenceId:--:customisedWayBackName",
    name: "competence-detail",
  },
  userMatricesList: {
    path: "list",
    name: "user-matrices-list",
  },
  userMatrixDetail: {
    path: "user-matrix-detail-:matrixId",
    name: "user-matrix-detail",
  },
  userCompetenceDetail: {
    path: "user-competence-detail--:matrixId:--:competenceId",
    name: "user-competence-detail",
  },
  surveyList: {
    path: "list",
    name: "survey-list",
  },
  surveyCreate: {
    path: "create",
    name: "survey-create",
  },
  addQuestion: {
    path: "create/add-question",
    name: "add-question",
  },
  surveyDetail: {
    path: "survey-detail-:surveyId",
    name: "survey-detail",
  },
  surveyInformation: {
    path: "survey-information",
    name: "survey-information",
  },
  surveyRespondents: {
    path: "survey-respondents",
    name: "survey-respondents",
  },
  clonePoll: {
    path: "clone",
    name: "clone-poll",
  },
  respondentDetails: {
    path: "survey-detail-:surveyId/survey-respondents/:respondentId",
    name: "respondent-details",
  },
  surveyResults: {
    path: "survey-results",
    name: "survey-results",
  },
  tariffsSubscriptions: {
    path: "subscriptions",
    name: "tariffs-subscriptions",
  },
  tariffsPackages: {
    path: "packages",
    name: "tariffs-packages",
  },
  profileInfo: {
    path: "info",
    name: "profile-info",
  },
  profileConnectedAccounts: {
    path: "connected-accounts",
    name: "profile-connected-accounts",
  },
  profileChangePassword: {
    path: "change-password",
    name: "profile-change-password",
  },
}

export const PATHS_PUBLIC = [
  PATHS.register.path,
  PATHS.login.path,
  PATHS.forgotPassword.path,
  PATHS.resetPassword.path,
  PATHS.confirmEmail.path,
  PATHS.googleAuth.path,
  PATHS.mailAuth.path,
  PATHS.yandexAuth.path,
  PATHS.authFailed.path,
]

export const addPathsToVueApp = (app: App): App => {
  app.provide(PATH_KEY, PATHS)
  return app
}
