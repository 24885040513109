import { createToast } from "mosha-vue-toastify"
import { h } from "vue"
import { isElementInDomAndHasText } from "@shared/utils/checkElementInDom"

import { ToastSuccess, ToastDanger } from "@shared/components/toast"

class ToastService {
  private static createToast(content: any, settings: any) {
    const options = {
      position: "top-right",
      hideProgressBar: true,
      showCloseButton: false,
      swipeClose: true,
      ...settings,
    }

    return createToast(content, options)
  }

  showToastSuccess(message: string) {
    const component = h(ToastSuccess, { message })

    ToastService.createToast(component, {
      toastBackgroundColor: "#169e6d",
    })
  }

  showToastDanger(message: string) {
    const isSameToastOnScreen = !isElementInDomAndHasText(
      ".toast-danger__text",
      message
    )
    if (!isSameToastOnScreen) return
    const component = h(ToastDanger, { message })

    ToastService.createToast(component, {
      toastBackgroundColor: "#f04438",
    })
  }
}
export const toastServiceDirect = new ToastService()
