<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.83333 2.5H4.16667C3.24583 2.5 2.5 3.24583 2.5 4.16667V15.8333C2.5 16.7542 3.24583 17.5 4.16667 17.5H14.1667C15.0875 17.5 15.8333 16.7542 15.8333 15.8333V15"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="square"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.8335 14.1668L13.4585 13.8426C13.6427 13.8201 13.8143 13.736 13.946 13.6051L17.8052 9.74595C18.5093 9.04178 18.5093 7.90012 17.8052 7.19512V7.19512C17.101 6.49095 15.9593 6.49095 15.2543 7.19512L11.4543 10.9951C11.3268 11.1226 11.2443 11.2876 11.2185 11.4668L10.8335 14.1668Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.2502 3.75H7.0835C6.3935 3.75 5.8335 3.19 5.8335 2.5V2.5C5.8335 1.81 6.3935 1.25 7.0835 1.25H11.2502C11.9402 1.25 12.5002 1.81 12.5002 2.5V2.5C12.5002 3.19 11.9402 3.75 11.2502 3.75Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.8335 6.66667H10.8335"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="square"
      stroke-linejoin="round"
    />
    <path
      d="M5.8335 10.0002H9.16683"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="square"
      stroke-linejoin="round"
    />
    <path
      d="M12.5 2.5H14.1667C15.0875 2.5 15.8333 3.24583 15.8333 4.16667"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="square"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "SurveyPageIcon",
}
</script>
