import { inject, injectable } from "inversify"
import { tap, map, catchError, switchMap, of, filter } from "rxjs"
import { v4 } from "uuid"

import {
  ROOT_SURVEY_STORE_KEY,
  SURVEY_API_SERVICE_KEY,
  TOAST_SERVICE_KEY,
  RESPONDENT_API_SERVICE,
  RESPONDENT_RATING_API_SERVICE,
} from "@global/di/keys"
import {
  RespondentApiService,
  RespondentRatingApiService,
  SurveyApiService,
  ToastService,
} from "@global/di/services"
import {
  TRootSurveyStoreModel,
  FAKE_SURVEY_DETAIL_MODEL,
  TSurveyDetailModelSnapshotIn,
  TRespondentModelSnapshotIn,
  RespondentModel,
  TGroupReportingTableModelSnapshotIn,
  TGroupReportHeaderTableModelSnapshotIn,
  TGroupReportRowModelSnapshotIn,
  TCompetenceModelSnapshotIn,
} from "@survey/models"
import {
  IColumn,
  IGroupReportTable,
  IRespondent,
  IRow,
  ISurveyDetailExtended,
} from "@survey/interfaces"

@injectable()
export class DetailSurveyService {
  @inject(ROOT_SURVEY_STORE_KEY)
  private readonly surveyStore: TRootSurveyStoreModel

  @inject(SURVEY_API_SERVICE_KEY)
  private readonly surveyApiService: SurveyApiService

  @inject(RESPONDENT_API_SERVICE)
  private readonly respondentApiService: RespondentApiService

  @inject(RESPONDENT_RATING_API_SERVICE)
  private readonly respondentRatingApiService: RespondentRatingApiService

  @inject(TOAST_SERVICE_KEY)
  private readonly toastService: ToastService

  prepareRespondent(respondent: IRespondent): TRespondentModelSnapshotIn {
    const pollLinkExpirationTime = respondent.pollLinkExpirationTime
      ? new Date(respondent.pollLinkExpirationTime)
      : null

    return {
      id: respondent.id,
      isExpired: respondent.isExpired,
      created: new Date(respondent.created),
      responseStatus: respondent.responseStatus.toString(),
      email: respondent.userEmail,
      name: respondent.userName,
      phone: respondent.userPhone,
      resumeLink: respondent.userResumeUrl,
      pollLinkExpirationTime,
      isVisible: respondent.isVisible,
      isRead: respondent.isRead,
      isNew: respondent.isNew,
    }
  }

  prepareRespondentList(data: IRespondent[]) {
    return data.reduce<Record<string, TRespondentModelSnapshotIn>>(
      (acc, respondent) => {
        if (!acc[respondent.id]) {
          acc[respondent.id] = this.prepareRespondent(respondent)
        }

        return acc
      },
      {}
    )
  }

  prepareSurveyDetail(
    data: ISurveyDetailExtended
  ): TSurveyDetailModelSnapshotIn {
    return {
      id: data.id,
      clientMatrixId: data.clientMatrixId,
      clientMatrixName: data.clientMatrixName,
      defaultPollLinkLifetimeDays: data.defaultPollLinkLifetimeDays,
      pollName: data.pollName,
      isAnonymous: data.isAnonymous,
      isUserNameVisible: data.isUserNameVisible,
      isUserNameRequired: data.isUserNameRequired,
      isUserEmailVisible: data.isUserEmailVisible,
      isUserEmailRequired: data.isUserEmailRequired,
      isUserPhoneVisible: data.isUserPhoneVisible,
      isUserPhoneRequired: data.isUserPhoneRequired,
      isUserResumeUrlVisible: data.isUserResumeUrlVisible,
      isUserResumeUrlRequired: data.isUserResumeUrlRequired,
      pollLinkExpirationTime: new Date(data.pollLinkExpirationTime),
      competences: data.competences.reduce<
        Record<string, TCompetenceModelSnapshotIn>
      >((acc, competence) => {
        if (!acc[competence.id]) {
          acc[competence.id] = {
            id: competence.id,
            name: competence.name,
          }
        }

        return acc
      }, {}),
    }
  }

  prepareGroupReportCol(columns: IColumn[]) {
    return columns.reduce<
      Record<string, TGroupReportHeaderTableModelSnapshotIn>
    >((acc, item, index) => {
      if (item.groupName === null && item.name) {
        acc[item.name] = {
          groupName: item.name,
          columnType: item.columnType.toString(),
        }

        return acc
      }

      if (item.groupName) {
        if (!acc[item.groupName]) {
          acc[item.groupName] = {
            groupName: item.groupName,
            columnType: item.columnType.toString(),
          }
        }

        acc[item.groupName] = {
          ...acc[item.groupName],
          names: [
            ...(acc[item.groupName].names || []),
            {
              columnNumber: index,
              columnType: item.columnType.toString(),
              value: item.name,
            },
          ],
        }

        return acc
      }

      return acc
    }, {})
  }

  prepareGroupReportRow(columns: IColumn[], rows: IRow[]) {
    return rows.reduce<Record<string, TGroupReportRowModelSnapshotIn>>(
      (acc, row) => {
        const id = v4()

        acc[id] = {
          id,
          respondent: row.respondentId,
          respondentId: row.respondentId,
          cells: row.cells.map((cell, index) => ({
            columnNumber: index,
            columnType: columns[index].columnType.toString(),
            value: String(cell),
          })),
          isVisible: row.isVisible,
        }

        return acc
      },
      {}
    )
  }

  prepareGroupReportingTable(
    data: IGroupReportTable
  ): TGroupReportingTableModelSnapshotIn {
    return {
      id: v4(),
      totalCounts: data.totalRatingsCount,
      groupReportCols: this.prepareGroupReportCol(data.columns),
      groupReportRows: this.prepareGroupReportRow(data.columns, data.rows),
    }
  }

  getSurveyDetailById$(surveyId: string) {
    return this.surveyApiService.getSurveyById$(surveyId).pipe(
      map((res) => this.prepareSurveyDetail({ id: surveyId, ...res })),
      tap((model) => this.surveyStore.setSurveyDetail(model)),
      catchError((err) => this.toastService.showToastDanger$(err.message))
    )
  }

  fetchRespondentsList$() {
    return of(this.surveyStore.surveyDetail.id).pipe(
      filter((pollId) => pollId !== "0"),
      switchMap((pollId) =>
        this.respondentApiService.getRespondentsList$(pollId)
      ),
      map((res) => this.prepareRespondentList(res)),
      tap({
        next: (respondents) =>
          this.surveyStore.surveyDetail.setRespondents(respondents),
      })
    )
  }

  addRespondent$(
    name: string,
    email: string,
    userPhone: string,
    userResumeUrl: string | null
  ) {
    return of(this.surveyStore.surveyDetail.id).pipe(
      filter((pollId) => pollId !== "0"),
      switchMap((pollId) =>
        this.respondentApiService.addRespondent$(
          name,
          email,
          userPhone,
          userResumeUrl,
          pollId
        )
      ),
      map((res) => this.prepareRespondent(res)),
      map((snapshot) => RespondentModel.create(snapshot)),
      tap({
        next: (model) => this.surveyStore.surveyDetail.addRespondent(model),
      }),
      switchMap(() =>
        this.toastService.showToastSuccess$("Респондент добавлен")
      )
    )
  }
}
