export class XHR422Error extends Error {
  constructor(
    public message = "Запрос верный, но сервер не смог его обработать.",
    // Файл планируем удалять, нет смысла решать проблему с типами
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public fullData: any = null
  ) {
    super()
  }
}
