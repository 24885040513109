<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.25 13.544V4.62724C7.25 4.10974 6.98375 3.62899 6.545 3.35524L3.545 1.48024C2.546 0.856236 1.25 1.57399 1.25 2.75224V11.6682C1.25 12.1857 1.51625 12.6665 1.955 12.9402L4.955 14.8152C5.954 15.44 7.25 14.7215 7.25 13.544Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.34998 7.25H13.85"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="square"
      stroke-linejoin="round"
    />
    <path
      d="M13.25 8.75L14.75 7.25L13.25 5.75"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="square"
      stroke-linejoin="round"
    />
    <path
      d="M7.25 13.25H10.25C11.0787 13.25 11.75 12.5788 11.75 11.75V11"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="square"
      stroke-linejoin="round"
    />
    <path
      d="M11.75 3.5V2.75C11.75 1.92125 11.0788 1.25 10.25 1.25H2.75"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="square"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "ExitIcon",
}
</script>
