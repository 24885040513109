import { apiServiceDirect } from "@global/services/api/apiServiceDirect"
import Axios, { AxiosResponse } from "axios"
import { toastServiceDirect } from "@shared/services/toastServiceDirect"
import {
  RoleTypes,
  TMember,
  TOrganization,
  TOrganizationJoinRequest,
} from "@/organizations/types/organizations"
import { getErrorMessage } from "@global/utils/getErrorMessage"

const companiesServiceBaseUrl = process.env.VUE_APP_ORGANIZATIONS_SERVICE_API

class OrganizationsService {
  async getOrganizations() {
    try {
      const res: AxiosResponse<TOrganization[]> = await apiServiceDirect.get(
        `${companiesServiceBaseUrl}/v1/Organizations`
      )
      return res.data
    } catch (error) {
      if (Axios.isAxiosError(error)) {
        toastServiceDirect.showToastDanger(getErrorMessage(error))
      } else {
        toastServiceDirect.showToastDanger("Не удалось добавить компанию")
      }
    }
  }

  async getCurrentOrganization(accountId: TMember["accountId"]) {
    try {
      const res: AxiosResponse<
        {
          accountId: string
          createdAt: string
          organizationId: string
          role: RoleTypes
          organization: TOrganization
          isCurrent: boolean
        }[]
      > = await apiServiceDirect.get(
        `${companiesServiceBaseUrl}/v1/Members?$expand=organization&$filter=accountId eq ${accountId}`
      )
      return res.data
    } catch (error) {
      if (Axios.isAxiosError(error)) {
        toastServiceDirect.showToastDanger(getErrorMessage(error))
      } else {
        toastServiceDirect.showToastDanger(
          "Не удалось получить данные о текущей компании"
        )
      }
    }
  }

  async getOrganizationsByMember(accountId: string) {
    const response: AxiosResponse<TMember[]> = await apiServiceDirect.get(
      `${companiesServiceBaseUrl}/v1/Members?$expand=Organization&$filter=accountId eq ${accountId}`
    )
    return response.data
  }

  async getCompany(companyId: string) {
    try {
      const res: AxiosResponse<TOrganization> = await apiServiceDirect.get(
        `${companiesServiceBaseUrl}/v1/Organizations/${companyId}?$expand=Members`
      )
      return res.data
    } catch (error) {
      if (Axios.isAxiosError(error)) {
        toastServiceDirect.showToastDanger(getErrorMessage(error))
      } else {
        toastServiceDirect.showToastDanger("Не удалось добавить компанию")
      }
    }
  }

  async createCompany(companyInfo: {
    name: string
    inn: string
    kpp?: string
    ogrn: string
    legalAddress: string
    postalAddress: string
    chiefName: string
    phone: string
  }) {
    try {
      const res: AxiosResponse<TOrganization> = await apiServiceDirect.post(
        `${companiesServiceBaseUrl}/v1/Organizations`,
        companyInfo
      )
      return res.data
    } catch (error) {
      if (Axios.isAxiosError(error)) {
        if (error.response?.status === 400) {
          throw error
        }

        if (
          error.response?.status === 409 &&
          error.response.data.data.message ===
            "Entity Organization with Inn value already exist"
        ) {
          throw error
        }

        toastServiceDirect.showToastDanger(getErrorMessage(error))
      } else {
        toastServiceDirect.showToastDanger("Не удалось добавить компанию")
      }
    }
  }

  async editCompany(
    companyId: string,
    companyInfo: {
      name: string
      inn: string
      kpp?: string
      ogrn: string
      legalAddress: string
      postalAddress: string
      chiefName: string
      phone: string
    }
  ) {
    try {
      const res: AxiosResponse<TOrganization> = await apiServiceDirect.put(
        `${companiesServiceBaseUrl}/v1/Organizations/${companyId}`,
        companyInfo
      )
      return res.data
    } catch (error) {
      if (Axios.isAxiosError(error)) {
        if (error.response?.status === 400) {
          throw error
        } else {
          toastServiceDirect.showToastDanger(getErrorMessage(error))
        }
      } else {
        toastServiceDirect.showToastDanger("Не удалось добавить компанию")
      }
    }
  }

  async deleteCompany(companyId: string) {
    try {
      return await apiServiceDirect.delete(
        `${companiesServiceBaseUrl}/v1/Organizations/${companyId}`
      )
    } catch (error) {
      if (Axios.isAxiosError(error)) {
        toastServiceDirect.showToastDanger(getErrorMessage(error))
      } else {
        toastServiceDirect.showToastDanger("Не удалось удалить компанию")
      }
    }
  }

  async getCompanyInfo(inn: string, kpp: string) {
    try {
      const res: AxiosResponse<{
        chiefName: string
        fullName: string
        fullNameWithOpf: string
        inn: string
        kpp: string
        name: string
        ogrn: string
        shortName: string
        shortNameWithOpf: string
      }> = await apiServiceDirect.post(
        `${companiesServiceBaseUrl}/v1/Organizations/GetOneByInn`,
        { inn, kpp }
      )
      return res.data
    } catch (error) {
      if (Axios.isAxiosError(error)) {
        console.log(getErrorMessage(error))
        return
      } else {
        console.log("Не удалось получить данные о компании")
      }
    }
  }

  async deleteMember(
    accountId: string,
    roleId: string,
    organizationId: string
  ) {
    try {
      return await apiServiceDirect.delete(
        `${companiesServiceBaseUrl}/v1/Members`,
        {
          accountId,
          roleId,
          organizationId,
        }
      )
    } catch (error) {
      if (Axios.isAxiosError(error)) {
        toastServiceDirect.showToastDanger(getErrorMessage(error))
      } else {
        toastServiceDirect.showToastDanger("Не удалось удалить участника")
      }
    }
  }

  async getJoinOrganizationRequests(organizationId?: TOrganization["id"]) {
    try {
      const query = organizationId ? `?organizationId=${organizationId}` : ""

      const { data }: AxiosResponse<TOrganizationJoinRequest[]> =
        await apiServiceDirect.get(
          `${companiesServiceBaseUrl}/v1/Requests${query}`
        )
      return data
    } catch (error) {
      if (Axios.isAxiosError(error)) {
        console.log(getErrorMessage(error))
        return
      } else {
        console.log(
          "Не удалось получить данные о запросах на участие в компании"
        )
      }
    }
  }

  async createJoinOrganizationRequest(organizationId: string) {
    try {
      const { data }: AxiosResponse<TOrganizationJoinRequest> =
        await apiServiceDirect.post(`${companiesServiceBaseUrl}/v1/Requests`, {
          organizationId,
        })
      return data
    } catch (error) {
      if (Axios.isAxiosError(error)) {
        console.log(getErrorMessage(error))
        return
      } else {
        console.log("Не удалось создать запрос на участие в компании")
      }
    }
  }

  async approveJoinOrganizationRequest(requestId: string) {
    await apiServiceDirect.post(
      `${companiesServiceBaseUrl}/v1/Requests/${requestId}/approve`,
      { id: requestId }
    )
  }
  async declineJoinOrganizationRequest(requestId: string) {
    await apiServiceDirect.post(
      `${companiesServiceBaseUrl}/v1/Requests/${requestId}/decline`,
      { id: requestId }
    )
  }

  async inviteMemberByEmail(
    senderName: string,
    email: string,
    organizationId: string
  ) {
    try {
      return await apiServiceDirect.post(
        `${companiesServiceBaseUrl}/v1/Invitations`,
        { senderName, email, organizationId }
      )
    } catch (error) {
      if (Axios.isAxiosError(error)) {
        toastServiceDirect.showToastDanger(getErrorMessage(error))
      } else {
        console.log("Не удалось пригласить участника")
      }
    }
  }

  async acceptInvitationToken(invitationToken: string) {
    try {
      const response: AxiosResponse<{
        organizationId: string
      }> = await apiServiceDirect.post(
        `${companiesServiceBaseUrl}/v1/Invitations/accept?invitationToken=${invitationToken}`,
        {}
      )
      return response.data
    } catch (error) {
      // Ошибки пока скрыты, т.к. не удалось найти причину ошибки 500 на бэкенде. Фактически метод отрабатывает корректно
      if (Axios.isAxiosError(error)) {
        // toastServiceDirect.showToastDanger(getErrorMessage(error))
      } else {
        // console.log("Не удалось принять приглашение на участие в компании")
      }
    }
  }

  async changeMemberRole(params: {
    accountId: string
    role: RoleTypes
    organizationId: string
  }) {
    try {
      const response: AxiosResponse<{
        organizationId: string
      }> = await apiServiceDirect.put(
        `${companiesServiceBaseUrl}/v1/Members`,
        params
      )
      return response.data
    } catch (error) {
      if (Axios.isAxiosError(error)) {
        toastServiceDirect.showToastDanger(getErrorMessage(error))
      } else {
        console.log("Не удалось изменить роль участника компании")
      }
    }
  }

  async setCurrentOrganization(organizationId: TOrganization["id"]) {
    try {
      return await apiServiceDirect.post(
        `${companiesServiceBaseUrl}/v1/Organizations/${organizationId}/SetCurrent`,
        {}
      )
    } catch (error) {
      if (Axios.isAxiosError(error)) {
        toastServiceDirect.showToastDanger(getErrorMessage(error))
      } else {
        console.log("Не удалось изменить текущую компанию")
      }
    }
  }
}

export const organizationsService = new OrganizationsService()
