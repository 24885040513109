import { apiServiceDirect } from "@/global/services/api/apiServiceDirect"
import { toastServiceDirect } from "@shared/services/toastServiceDirect"
import Axios, { AxiosResponse } from "axios"
import { getErrorMessage } from "@global/utils/getErrorMessage"
import { TAuthData } from "@auth/types/authTypes"
import { useAuthStore } from "@auth/store/authStore"

const authServiceBaseUrl = `${process.env.VUE_APP_ACCOUNT_SERVICE_API}api`

class AuthService {
  async login(email: string, password: string) {
    try {
      const response: AxiosResponse<TAuthData> = await apiServiceDirect.post(
        `${authServiceBaseUrl}/v1/users/login`,
        {
          requestedAt: new Date().toISOString(),
          email: email.toLowerCase(),
          password,
        }
      )
      return response.data
    } catch (error) {
      if (Axios.isAxiosError(error)) {
        if (
          error.response?.status &&
          [400, 404, 422].includes(error.response?.status)
        ) {
          throw error
        }
        toastServiceDirect.showToastDanger(getErrorMessage(error))
      } else {
        toastServiceDirect.showToastDanger("Не удалось войти")
      }
    }
  }

  async logout() {
    try {
      return await apiServiceDirect.post(
        `${authServiceBaseUrl}/v1/users/logout`,
        {}
      )
    } catch (error) {
      if (Axios.isAxiosError(error)) {
        toastServiceDirect.showToastDanger(getErrorMessage(error))
      } else {
        toastServiceDirect.showToastDanger("Не удалось выйти")
      }
    }
  }

  async refreshToken(refreshToken: TAuthData["refreshToken"]) {
    try {
      const response: AxiosResponse<TAuthData> = await apiServiceDirect.post(
        `${authServiceBaseUrl}/v1/users/refresh`,
        { refreshToken }
      )
      return response.data
    } catch (error) {
      if (Axios.isAxiosError(error)) {
        console.log(getErrorMessage(error))
        const authStore = useAuthStore()
        authStore.removeAuthData()
        authStore.pushToAuthFailed()
      } else {
        console.log("Не удалось обновить токен")
      }
    }
  }

  async forgotPassword(email: string) {
    try {
      await apiServiceDirect.post(
        `${authServiceBaseUrl}/v1/users/forgotPassword`,
        {
          email: email.toLowerCase(),
        }
      )
      return true
    } catch (error) {
      if (Axios.isAxiosError(error)) {
        if (
          error.response?.status &&
          [400, 404].includes(error.response.status)
        ) {
          throw error
        }
        toastServiceDirect.showToastDanger(getErrorMessage(error))
      } else {
        toastServiceDirect.showToastDanger("Не удалось восстановить пароль")
      }
    }
  }

  async resetPassword(newPassword: string, resetPasswordToken: string) {
    try {
      await apiServiceDirect.post(
        `${authServiceBaseUrl}/v1/users/resetPassword`,
        {
          requestedAt: new Date().toISOString(),
          newPassword,
          resetPasswordToken,
        }
      )
      return true
    } catch (error) {
      if (Axios.isAxiosError(error)) {
        if (error.response?.status && [422].includes(error.response.status)) {
          throw error
        }
        toastServiceDirect.showToastDanger(getErrorMessage(error))
      } else {
        toastServiceDirect.showToastDanger("Не удалось восстановить пароль")
      }
    }
  }

  async changePassword(data: { currentPassword: string; newPassword: string }) {
    try {
      return await apiServiceDirect.put(
        `${authServiceBaseUrl}/v1/users/changePassword`,
        data
      )
    } catch (error) {
      if (Axios.isAxiosError(error)) {
        if (
          error.response?.status &&
          [400, 422].includes(error.response.status)
        ) {
          throw error
        }
        toastServiceDirect.showToastDanger(getErrorMessage(error))
      } else {
        toastServiceDirect.showToastDanger("Не удалось изменить пароль")
      }
    }
  }

  async register(data: {
    type: number
    name: string
    email: string
    phone?: string
    password: string
    organization?:
      | {
          name: string
          inn: string
          kpp: string
          ogrn: string
          legalAddress: string
          postalAddress: string
          chiefName: string
          phone: string
        }
      | Record<string, string | undefined>
  }) {
    data.email = data.email.toLowerCase()
    try {
      return await apiServiceDirect.post(
        `${authServiceBaseUrl}/v1/users/register`,
        data
      )
    } catch (error) {
      if (Axios.isAxiosError(error)) {
        if (error.response?.status === 400) {
          throw error
        }

        if (
          error.response?.status === 409 &&
          [
            "Entity RegisterUserRequest with Requested resource already exists value already exist",
            "Entity Organization with Inn value already exist",
          ].includes(error.response.data.data.message)
        ) {
          throw error
        }

        toastServiceDirect.showToastDanger(getErrorMessage(error))
      } else {
        toastServiceDirect.showToastDanger("Не удалось зарегистрироваться")
      }
    }
  }

  async confirmEmail(confirmationToken: string) {
    try {
      await apiServiceDirect.post(`${authServiceBaseUrl}/v1/users/confirm`, {
        requestedAt: new Date().toISOString(),
        confirmationToken,
      })
      return true
    } catch (error) {
      if (Axios.isAxiosError(error)) {
        if (
          error.response?.status === 404 &&
          error.response.data.data.message.includes("was not found")
        ) {
          toastServiceDirect.showToastDanger("Ссылка недействительна")
          return
        }

        if (
          error.response?.status === 422 &&
          error.response.data.data.message === "Confirmation is expired"
        ) {
          toastServiceDirect.showToastDanger("Срок действия ссылки истёк")
          return
        }

        toastServiceDirect.showToastDanger(getErrorMessage(error))
      } else {
        toastServiceDirect.showToastDanger("Не удалось подтвердить эл. почту")
      }
    }
  }

  async authThroughGoogle(code: string) {
    try {
      const response: AxiosResponse<TAuthData> = await apiServiceDirect.post(
        `${authServiceBaseUrl}/v1/omniauth/google`,
        {
          code,
          redirectUri: process.env.VUE_APP_GOOGLE_AUTH_REDIRECT_URI,
        }
      )
      return response.data
    } catch (error) {
      if (Axios.isAxiosError(error)) {
        toastServiceDirect.showToastDanger(getErrorMessage(error))
        throw error
      } else {
        toastServiceDirect.showToastDanger("Не удалось авторизоваться")
        throw error
      }
    }
  }

  async authThroughYandex(code: string) {
    try {
      const response: AxiosResponse<TAuthData> = await apiServiceDirect.post(
        `${authServiceBaseUrl}/v1/omniauth/yandex`,
        {
          code,
          redirectUri: process.env.VUE_APP_YANDEX_AUTH_REDIRECT_URI,
        }
      )
      return response.data
    } catch (error) {
      if (Axios.isAxiosError(error)) {
        toastServiceDirect.showToastDanger(getErrorMessage(error))
        throw error
      } else {
        toastServiceDirect.showToastDanger("Не удалось авторизоваться")
        throw error
      }
    }
  }

  async authThroughMail(code: string) {
    try {
      const response: AxiosResponse<TAuthData> = await apiServiceDirect.post(
        `${authServiceBaseUrl}/v1/omniauth/mail`,
        {
          code,
          redirectUri: process.env.VUE_APP_MAIL_AUTH_REDIRECT_URI,
        }
      )
      return response.data
    } catch (error) {
      if (Axios.isAxiosError(error)) {
        toastServiceDirect.showToastDanger(getErrorMessage(error))
        throw error
      } else {
        toastServiceDirect.showToastDanger("Не удалось авторизоваться")
        throw error
      }
    }
  }

  async getUsers(params?: { usersAccountIds?: string[] }) {
    let queryString = ""

    if (params?.usersAccountIds) {
      queryString += `$expand=account&$filter=account/id in ('${params.usersAccountIds.join(
        "', '"
      )}')`
    }

    if (queryString.length) {
      queryString = `?${queryString}`
    }
    try {
      const {
        data,
      }: AxiosResponse<
        {
          account: {
            id: string
          }
          name: string
          email: string
          confirmedAt: string
        }[]
      > = await apiServiceDirect.get(
        `${authServiceBaseUrl}/v1/users${queryString ?? ""}`
      )
      return data
    } catch (error) {
      if (Axios.isAxiosError(error)) {
        console.log(getErrorMessage(error))
        return
      } else {
        console.log("Не удалось получить данные о пользователях")
      }
    }
  }

  async resendEmail(email: string) {
    try {
      return await apiServiceDirect.put(
        `${authServiceBaseUrl}/v1/users/resend`,
        {
          email: email.toLowerCase(),
        }
      )
    } catch (error) {
      if (Axios.isAxiosError(error)) {
        toastServiceDirect.showToastDanger(getErrorMessage(error))
      } else {
        toastServiceDirect.showToastDanger(
          "Не удалось повторно отправить письмо"
        )
      }
    }
  }

  async getCurrentUser() {
    try {
      const response: AxiosResponse<{
        email: string
        name: string
        account: {
          id: string
        }
      }> = await apiServiceDirect.get(`${authServiceBaseUrl}/v1/users/current`)
      return response.data
    } catch (error) {
      if (Axios.isAxiosError(error)) {
        toastServiceDirect.showToastDanger(getErrorMessage(error))
      } else {
        console.log("Не удалось получить данные о пользователе")
      }
    }
  }

  async changeCurrentUserInfo(data: { name: string }) {
    try {
      return await apiServiceDirect.post(
        `${authServiceBaseUrl}/v1/users/current`,
        data
      )
    } catch (error) {
      if (Axios.isAxiosError(error)) {
        toastServiceDirect.showToastDanger(getErrorMessage(error))
        throw error
      } else {
        toastServiceDirect.showToastDanger("Не удалось авторизоваться")
        throw error
      }
    }
  }
}

export const authService = new AuthService()
