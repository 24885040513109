import Axios, { AxiosError } from "axios"
import { TApiBasicError } from "@global/types/global"

export function getErrorMessage(error: AxiosError<TApiBasicError>) {
  if (error.response && Axios.isAxiosError(error)) {
    const { statusText, data } = error.response

    if (data?.data) {
      const message = data?.data?.message
      return message?.length
        ? message
        : statusText?.length
        ? statusText
        : error.message
    }
  }
  return error.message
}
