<template>
  <component :is="elementType" class="base-btn" v-bind="btnAttributes">
    <!-- @slot Текст кнопки -->
    <slot />
    <DropdownIcon v-if="withDropdownIcon" class="btn-dropdown__icon" />
    <Spiner v-if="loading" />
  </component>
</template>

<script>
import DropdownIcon from "@layout/assets/icons/dropdown-icon.vue"
import Spiner from "./Spinner.vue"

export default {
  name: "BaseButton",

  components: {
    DropdownIcon,
    Spiner,
  },

  props: {
    /**
     * Стиль кнопки (по умолчанию оранжевый фон и белый текст)
     *
     * outline - белый фон, оранжевый текст и оранжевая сплошная рамка
     * outline-dashed - как outline, но border-style: dashed и другой ховер
     * empty - без рамки и фона
     * action - кнопка с иконкой внутри (используется в таблицах)
     *
     * @values outline, outline-dashed, empty, action
     *
     * @type {string}
     */
    btnType: {
      type: String,
      default: undefined,
      validator(value) {
        return ["outline", "outline-dashed", "empty", "action"].includes(value)
      },
    },

    /**
     * Основной цвет текста кнопки (при ховере текст всегда оранжевый)
     *
     * @values dark, orange, white
     *
     * @type {string}
     */
    textColor: {
      type: String,
      default: undefined,
      validator(value) {
        return ["dark", "orange", "white"].includes(value)
      },
    },

    /**
     * Кнопка занимает всю ширину
     *
     * @type {boolean}
     */
    block: {
      type: Boolean,
      default: false,
    },

    /**
     * Отключена ли кнопка
     *
     * @type {boolean}
     */
    disabled: {
      type: Boolean,
      default: false,
    },

    /**
     * Кнопка со стрелкой для dropdown
     *
     * @type {boolean}
     */
    withDropdownIcon: {
      type: Boolean,
      default: false,
    },

    /**
     * Соcтояние загрузки
     *
     * @type {boolean}
     */
    loading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    /**
     * Атрибуты кнопки
     * @type {object}
     */
    btnAttributes() {
      const elementClasses = {
        "base-btn_disabled": this.disabled,
        "block-btn": this.block,
        "btn-dropdown": this.withDropdownIcon,
        [`btn-text_${this.textColor}`]: this.textColor,
        [`base-btn_${this.btnType}`]: this.btnType,
      }

      return {
        class: elementClasses,
        type: this.isAttributeType,
        disabled: this.disabled,
      }
    },

    /**
     * Определяем тип используемого элемента (ссылка или кнопка)
     * @type {string}
     */
    elementType() {
      const hasLinkAttributte = "to" in this.$attrs
      return hasLinkAttributte ? "router-link" : "button"
    },

    /**
     * Добавляется ли кнопке атрибут type (если используется ссылка то не нужен)
     * @type {?string}
     */
    isAttributeType() {
      const isButton = this.elementType === "button"
      const hasTypeAttribute = "type" in this.$attrs

      if (isButton && hasTypeAttribute) return this.$attrs.type
      if (isButton && !hasTypeAttribute) return "button"

      return null
    },
  },
}
</script>

<style lang="scss">
.base-btn {
  position: relative;
  display: flex;
  align-items: center;
  height: 42px;
  padding: 0 20px;
  font-size: 14px;
  line-height: 130%;
  color: white;
  background: $color-orange4;
  border-radius: 4px;
  transition-duration: 0.2s;
  transition-property: background-color, color, border-color;

  &:hover:not(.base-btn_disabled) {
    background-color: $color-orange3;
  }
}

// [OUTLINE]
.base-btn_outline {
  color: $color-orange4;
  background: $color-white;
  border: 1px solid $color-orange4;

  &:hover:not(.base-btn_disabled) {
    background-color: $color-orange1;
    border-color: transparent;
  }

  &.base-btn_disabled {
    background: $color-white;
    border-color: $color-gray9;
  }
}

// [OUTLINE-DASHED]
.base-btn_outline-dashed {
  color: $color-orange4;
  background: $color-white;
  border: 1px dashed $color-orange4;

  &:hover:not(.base-btn_disabled) {
    background-color: $color-orange1;
  }

  &.base-btn_disabled {
    background: $color-white;
    border-color: $color-gray9;
  }
}

// [EMPTY]
.base-btn_empty {
  color: $color-black;
  background: $color-white;

  &:hover:not(.base-btn_disabled) {
    color: $color-orange3;
    background: $color-white;
  }

  &.base-btn_disabled {
    background: $color-white;
  }
}

// [ACTION]
.base-btn_action {
  flex-shrink: 0;
  justify-content: center;
  padding: 12px;
  color: $color-black;
  background: none;
  border-radius: 0;

  &:hover:not(.base-btn_disabled) {
    color: $color-orange4;
    background: none;
  }

  &.base-btn_disabled {
    position: relative;
    background: none;

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      content: "";
    }
  }
}

// Стиль должен быть в конце, чтобы иметь приоритет
.base-btn_disabled {
  color: $color-gray9;
  cursor: default;
  background: $color-gray4;
}

// модификаторы кнопки
.block-btn {
  width: 100%;
}

.btn-dark-text {
  color: $color-black;
}

.btn-border-solid {
  border-style: solid;
}

.btn-border-dashed {
  border-style: dashed;
}

.btn-border-none {
  border-style: none;
}

.btn-dropdown {
  .btn-dropdown__icon {
    display: inline-flex;
    margin-left: 14px;
  }

  &:hover:not(.base-btn_disabled) {
    .btn-dropdown__icon {
      transform: rotate(180deg);
    }
  }
}

.btn-dropdown__icon {
  display: none;
  transition: transform 0.2s;
}

.btn-text_dark {
  color: $color-black;
}

.btn-text_orange {
  color: $color-orange4;
}

.btn-text_white {
  color: $color-white;
}
</style>
