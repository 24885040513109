import { inject, injectable } from "inversify"

import { API_SERVICE_KEY } from "@global/di/keys"
import { ApiService } from "@global/di/services"

@injectable()
export class RespondentRatingApiService {
  @inject(API_SERVICE_KEY)
  private readonly apiService: ApiService

  getGroupRatingReport$(pollId: string, fileType: 1 | 2) {
    return this.apiService.get<Blob>(
      "/v1/RespondentRating/GetGroupRatingReport",
      {},
      {
        responseType: "blob",
        params: {
          pollId,
          fileType,
          responseLinkTemplate: `${process.env.VUE_APP_QUESTIONNAIRE_STAND}personal-result-{pollResponseId}`,
        },
      }
    )
  }

  getPersonalRatingReport$(
    pollId: string,
    pollResponseId: string,
    fileType: 1 | 2
  ) {
    return this.apiService.get<Blob>(
      "/v1/RespondentRating/GetPersonalRatingReport",
      {},
      {
        responseType: "blob",
        params: {
          pollId,
          pollResponseId,
          fileType,
        },
      }
    )
  }

  getPersonalDevelopmentReport$(
    pollId: string,
    pollResponseId: string,
    fileType: 1 | 2
  ) {
    return this.apiService.get(
      "/v1/RespondentRating/GetPersonalDevelopmentReport",
      {},
      {
        responseType: "blob",
        params: {
          pollId,
          pollResponseId,
          fileType,
        },
      }
    )
  }

  getGroupDevelopmentCompetenceReport$(
    pollId: string,
    competenceId: string,
    fileType: 1 | 2
  ) {
    return this.apiService.get(
      "/v1/RespondentRating/GetGroupDevelopmentCompetenceReport",
      {},
      {
        responseType: "blob",
        params: {
          pollId,
          competenceId,
          fileType,
        },
      }
    )
  }

  getGroupDevelopmentGeneralReport$(pollId: string, fileType: 1 | 2) {
    return this.apiService.get(
      "/v1/RespondentRating/GetGroupDevelopmentGeneralReport",
      {},
      {
        responseType: "blob",
        params: {
          pollId,
          fileType,
        },
      }
    )
  }
}
