import { apiServiceDirect } from "@/global/services/api/apiServiceDirect"
import Axios, { AxiosResponse } from "axios"
import {
  ISubscription,
  ITariff,
  TGetTariffsResponse,
} from "../interfaces/billing.interface"
import { toastServiceDirect } from "@shared/services/toastServiceDirect"
import { v4 as uuidv4 } from "uuid"
import { getErrorMessage } from "@global/utils/getErrorMessage"

class BillingService {
  async getCurrentSubscription() {
    try {
      const res: AxiosResponse<{
        data: ISubscription
        subscriptionType: string
      }> = await apiServiceDirect.get("/v1/Billing/getCurrentSubscription")
      return res.data
    } catch (error) {
      if (Axios.isAxiosError(error)) {
        toastServiceDirect.showToastDanger(getErrorMessage(error))
      } else {
        toastServiceDirect.showToastDanger(
          "Не удалось загрузить данные о текущей подписке"
        )
      }
    }
  }

  async getTariffs() {
    try {
      const res: AxiosResponse<TGetTariffsResponse> =
        await apiServiceDirect.get("/v1/Billing/GetTariffs")
      return res.data.data.tariffGroups[0].tariffs
    } catch (error) {
      if (Axios.isAxiosError(error)) {
        toastServiceDirect.showToastDanger(getErrorMessage(error))
      } else {
        toastServiceDirect.showToastDanger(
          "Не удалось загрузить список тарифов"
        )
      }
    }
  }

  async getSubscriptions() {
    try {
      const res: AxiosResponse<{
        data: {
          correlationId: string
          subscriptions: ISubscription[]
        }
      }> = await apiServiceDirect.get("/v1/Billing/GetSubscriptions")
      return res.data.data.subscriptions
    } catch (error) {
      if (Axios.isAxiosError(error)) {
        toastServiceDirect.showToastDanger(getErrorMessage(error))
      } else {
        toastServiceDirect.showToastDanger(
          "Не удалось загрузить список подписок"
        )
      }
    }
  }

  async getPackages() {
    try {
      const res: AxiosResponse<{
        data: {
          correlationId: string
          packages: ITariff[]
        }
      }> = await apiServiceDirect.get("/v1/Billing/GetPackages")
      return res.data.data.packages
    } catch (error) {
      if (Axios.isAxiosError(error)) {
        toastServiceDirect.showToastDanger(getErrorMessage(error))
      } else {
        toastServiceDirect.showToastDanger(
          "Не удалось загрузить список пакетов"
        )
      }
    }
  }

  async getCurrentPromocode() {
    try {
      const res: AxiosResponse<{ data: { value: string } }> =
        await apiServiceDirect.get("/v1/Billing/GetPromoCode")
      return res.data.data.value
    } catch (error) {
      if (Axios.isAxiosError(error)) {
        toastServiceDirect.showToastDanger(getErrorMessage(error))
      } else {
        toastServiceDirect.showToastDanger(
          "Не удалось загрузить текущий промокод"
        )
      }
    }
  }

  async applyPromocode(promocode: string) {
    const data = {
      id: uuidv4(),
      created: new Date().toUTCString(),
      params: {
        value: promocode,
      },
    }

    try {
      const res: AxiosResponse<{
        data: {
          found: boolean
          expires?: string
        }
      }> = await apiServiceDirect.post("/v1/Billing/ApplyPromoCode", data)
      return res.data.data
    } catch (error) {
      if (Axios.isAxiosError(error)) {
        toastServiceDirect.showToastDanger(getErrorMessage(error))
      } else {
        toastServiceDirect.showToastDanger("Не удалось применить промокод")
      }
    }
  }

  async createSubscriptionOnlinePayment(tariffId: string) {
    const data = {
      params: {
        tariffId,
      },
    }
    try {
      const res: AxiosResponse<{ data: { url: string } }> =
        await apiServiceDirect.post("/v1/Billing/CreateOnlinePayment", data)
      return res.data.data.url
    } catch (error) {
      if (Axios.isAxiosError(error)) {
        toastServiceDirect.showToastDanger(getErrorMessage(error))
      } else {
        toastServiceDirect.showToastDanger(
          "Не удалось получить ссылку на онлайн-оплату"
        )
      }
    }
  }

  async createPackageOnlinePayment(tariffId: string) {
    const data = {
      params: {
        packageId: tariffId,
      },
    }
    try {
      const res: AxiosResponse<{ data: { url: string } }> =
        await apiServiceDirect.post(
          "/v1/Billing/CreatePackageOnlinePayment",
          data
        )
      return res.data.data.url
    } catch (error) {
      if (Axios.isAxiosError(error)) {
        toastServiceDirect.showToastDanger(getErrorMessage(error))
      } else {
        toastServiceDirect.showToastDanger(
          "Не удалось получить ссылку на онлайн-оплату"
        )
      }
    }
  }

  async createPaymentInvoice(
    tariffId: string,
    organization: {
      name: string
      inn: string
      kpp?: string
      ogrn: string
      legalAddress: string
      postalAddress: string
      chiefName: string
      phone: string
    }
  ) {
    const data = {
      id: uuidv4(),
      created: new Date().toISOString(),
      params: {
        tariffId: tariffId,
        organization: {
          name: organization.name,
          inn: organization.inn,
          kpp: organization.kpp,
          ogrn: organization.ogrn,
          legalAddress: organization.legalAddress,
          postalAddressIsSameAsLegalAddress:
            organization.legalAddress === organization.postalAddress,
          postalAddress: organization.postalAddress,
          chiefName: organization.chiefName,
          phone: organization.phone,
        },
      },
    }
    try {
      return await apiServiceDirect.post("/v1/Billing/CreatePaymentBill", data)
    } catch (error) {
      if (Axios.isAxiosError(error)) {
        toastServiceDirect.showToastDanger(getErrorMessage(error))
      } else {
        toastServiceDirect.showToastDanger("Не удалось создать счет на оплату")
      }
    }
  }
}

export const billingService = new BillingService()
