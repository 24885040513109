import Axios, { AxiosRequestConfig, InternalAxiosRequestConfig } from "axios"
import { useSubscriptionsStore } from "@billing/store/subscriptions"
import { authService } from "@auth/services/authService"
import { TAuthStore, useAuthStore } from "@auth/store/authStore"

class ApiServiceDirect {
  static getTimezoneOffset() {
    const offset = new Date().getTimezoneOffset()
    const minutes = Math.abs(offset)

    return `${offset < 0 ? "" : "-"}${minutes}`
  }

  private instance
  private authStore: TAuthStore

  constructor() {
    this.instance = Axios.create({
      baseURL: process.env.VUE_APP_API,
      headers: {
        "x-client-timezone": ApiServiceDirect.getTimezoneOffset(),
      },
    })

    this.instance.interceptors.request.use(
      (requestConfig: InternalAxiosRequestConfig) => {
        if (!this.authStore) {
          this.authStore = useAuthStore()
        }

        const accessToken = this.authStore.authData?.accessToken

        if (!requestConfig.headers || !accessToken) return requestConfig

        requestConfig.headers["Authorization"] = `Bearer ${accessToken}`

        return requestConfig
      }
    )

    this.createAxiosResponseInterceptor()

    this.instance.interceptors.response.use(
      this.extractSubscriptionInfo.bind(this)
    )
  }

  createAxiosResponseInterceptor() {
    const interceptor = this.instance.interceptors.response.use(
      (response) => response,
      async (error) => {
        if (
          error.response.status !== 401 ||
          error.response.data?.data?.message ===
            "The user does not have access to the requested resource"
        ) {
          return Promise.reject(error)
        }

        this.instance.interceptors.response.eject(interceptor)

        const refreshToken = this.authStore.authData?.refreshToken

        if (!refreshToken) return Promise.reject(error)

        await authService
          .refreshToken(refreshToken)
          .then(async (authData) => {
            if (!authData) {
              this.authStore.pushToAuthFailed()
              return
            }

            await this.authStore.setAuthData(authData, true)
            window.location.reload()
          })
          .catch((error) => {
            this.authStore.removeAuthData()
            this.authStore.pushToAuthFailed()
            return Promise.reject(error)
          })
          .finally(this.createAxiosResponseInterceptor)
      }
    )
  }

  private extractSubscriptionInfo(response: any) {
    useSubscriptionsStore().updateSubscriptionWithHeaders(response.headers)
    return response
  }

  private request<T = unknown>(
    method: string,
    url: string,
    data: object,
    config = {}
  ) {
    const reqInfo = {
      method,
      url,
      data,
      ...config,
    } as AxiosRequestConfig

    return this.instance.request<T>(reqInfo)
  }

  async get<T = unknown>(endpoint: string, data = {}, config = {}) {
    return await this.request<T>("GET", endpoint, data, config)
  }

  async post<T = unknown>(endpoint: string, data: object, config = {}) {
    return await this.request<T>("POST", endpoint, data, config)
  }
  async put<T = unknown>(endpoint: string, data: object, config = {}) {
    return await this.request<T>("PUT", endpoint, data, config)
  }
  async delete<T = unknown>(endpoint: string, data = {}, config = {}) {
    return await this.request<T>("DELETE", endpoint, data, config)
  }
}

export const apiServiceDirect = new ApiServiceDirect()
