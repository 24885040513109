interface MatrixState {
  modelName: string
  matrixId: string
  competenceId: string
  competenceLevel: number
  competencePriority: number
  competenceInMatrix: boolean
  matrixDisabled: boolean
  viewTemplateMatrix: boolean
  // стор будет удален, нет смысла решать проблему с типами
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cloneTemplateMatrix: any | null
}

export default {
  state: (): MatrixState => ({
    /**
     * Имя выбранной матрицы
     * @type {string}
     */
    modelName: "",

    /**
     * id выбранной матрицы
     * @type {string}
     */
    matrixId: "",

    /**
     * id выбранной компетенции
     * @type {string}
     */
    competenceId: "",

    /**
     * Уровень выбранной компетенции
     * @type {number}
     */
    competenceLevel: 0,

    /**
     * Вес выбранной компетенции
     * @type {number}
     */
    competencePriority: 0,

    /**
     * Находится ли выбранная компетенция в матрице
     * @type {boolean}
     */
    competenceInMatrix: false,

    /**
     * Заблокирована ли выбранная матрица (когда матриц больше чем предусмотрено тарифом)
     * @type {boolean}
     */
    matrixDisabled: false,

    /**
     * Просматривает ли пользователь шаблон матрицы
     * @type {boolean}
     */
    viewTemplateMatrix: false,

    /**
     * Объект шаблона матрицы для её добавления в список пользовательских
     * @type {?object}
     */
    cloneTemplateMatrix: null,
  }),

  mutations: {
    /**
     * Сохранить имя выбранной матрицы
     * @param {object} state - состояние хранилища Vuex
     * @param {string} modelName -имя матрицы
     */
    setMatrixName(state: MatrixState, modelName: string) {
      state.modelName = modelName
    },

    /**
     * Сохранить id выбранной матрицы
     * @param {object} state - состояние хранилища Vuex
     * @param {string} matrixId -id матрицы
     */
    setMatrixId(state: MatrixState, matrixId: string) {
      state.matrixId = matrixId
    },

    /**
     * Сохранить id выбранной компетенции
     * @param {object} state - состояние хранилища Vuex
     * @param {string} competenceId -id компетенции
     */
    setCompetenceId(state: MatrixState, competenceId: string) {
      state.competenceId = competenceId
    },

    /**
     * Сохранить уровень выбранной компетенции
     * @param {object} state - состояние хранилища Vuex
     * @param {number} competenceLevel - уровень компетенции
     */
    setCompetenceLevel(state: MatrixState, competenceLevel: number) {
      state.competenceLevel = competenceLevel
    },

    /**
     * Сохранить вес выбранной компетенции
     * @param {object} state - состояние хранилища Vuex
     * @param {number} competencePriority - вес компетенции
     */
    setСompetencePriority(state: MatrixState, competencePriority: number) {
      state.competencePriority = competencePriority
    },

    /**
     * Устанавливаем флаг, находится ли компетенция в матрице
     * @param {object} state - состояние хранилища Vuex
     * @param {boolean} competenceInMatrix - находится ли компетенция в матрице
     */
    setСompetenceInMatrix(state: MatrixState, competenceInMatrix: boolean) {
      state.competenceInMatrix = competenceInMatrix
    },

    /**
     * Устанавливаем флаг, указывающий просматривает ли пользователь шаблон матрицы
     * @param {object} state - состояние хранилища Vuex
     * @param {boolean} competenceInMatrix - просматривает ли пользователь шаблон матрицы
     */
    setViewStatusTemplateMatrix(
      state: MatrixState,
      viewTemplateMatrix: boolean
    ) {
      state.viewTemplateMatrix = viewTemplateMatrix
    },

    /**
     * Сохранить шаблон матрицы
     * @param {object} state - состояние хранилища Vuex
     * @param {object} templateMatrix - шаблон матрицы
     */
    // стор будет удален, нет смысла решать проблему с типами
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    saveCloneTemplateMatrix(state: MatrixState, templateMatrix: any) {
      state.cloneTemplateMatrix = templateMatrix
    },

    /**
     * Заблокировать выбранную матрицу
     * @param {object} state - состояние хранилища Vuex
     */
    lockMatrix(state: MatrixState) {
      state.matrixDisabled = true
    },

    /**
     * Разблокировать выбранную матрицу
     * @param {object} state - состояние хранилища Vuex
     */
    unlockMatrix(state: MatrixState) {
      state.matrixDisabled = false
    },
  },
}
