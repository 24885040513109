import { injectable } from "inversify"
import { Observable } from "rxjs"
import ClipboardJS from "clipboard"

@injectable()
export class ClipboardService {
  copyToClipboard$(message: string) {
    return new Observable((subscriber) => {
      const fakeElement = document.createElement("button")

      const clipboard = new ClipboardJS(fakeElement, {
        text: () => message,
        action: () => "copy",
      })

      clipboard.on("success", (event) => {
        clipboard.destroy()
        subscriber.next(event)
      })

      clipboard.on("error", (error) => {
        clipboard.destroy()
        subscriber.error(error)
      })

      fakeElement.click()
    })
  }
}
