import { Instance, SnapshotIn, SnapshotOut } from "mobx-state-tree"
import { GroupReportCellTableBaseModel } from "@survey/models/GroupReportCellTableBase.model"

export const GroupReportCellModel = GroupReportCellTableBaseModel.named(
  "GroupReportCellModel"
)

export type TGroupReportCellModel = Instance<typeof GroupReportCellModel>
export type TGroupReportCellModelSnapshotIn = SnapshotIn<
  typeof GroupReportCellModel
>
export type TGroupReportCellModelSnapshotOut = SnapshotOut<
  typeof GroupReportCellModel
>
