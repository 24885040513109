import { App } from "vue"
import { APP_ENV_KEY } from "@global/di/keys"

const addAppEnvs = (app: App) => {
  app.provide(APP_ENV_KEY, { ...process.env })

  return app
}

export { addAppEnvs }
