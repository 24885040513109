import Axios, { AxiosResponse } from "axios"
import { apiServiceDirect } from "@global/services/api/apiServiceDirect"
import { toastServiceDirect } from "@shared/services/toastServiceDirect"
import { getErrorMessage } from "@global/utils/getErrorMessage"
import { v4 as uuidv4 } from "uuid"
import { OnboardingStatuses } from "@layout/types/onboarding"

class OnboardingService {
  async getOnboardingStatus() {
    try {
      const response: AxiosResponse<{
        data: {
          onboardingStatus: OnboardingStatuses
        }
      }> = await apiServiceDirect.get("/v1/Account/GetOnboardingStatus")
      return response.data
    } catch (error) {
      if (Axios.isAxiosError(error)) {
        toastServiceDirect.showToastDanger(getErrorMessage(error))
      } else {
        toastServiceDirect.showToastDanger(
          "Не удалось получить данные статусе показа онбординга"
        )
      }
    }
  }

  async setOnboardingStatus(onboardingStatus: OnboardingStatuses) {
    try {
      return await apiServiceDirect.post("/v1/Account/SetOnboardingStatus/", {
        id: uuidv4(),
        created: new Date().toISOString(),
        params: {
          onboardingStatus,
        },
      })
    } catch (error) {
      if (Axios.isAxiosError(error)) {
        toastServiceDirect.showToastDanger(getErrorMessage(error))
      } else {
        toastServiceDirect.showToastDanger(
          "Не удалось установить статусе показа онбординга"
        )
      }
    }
  }
}

export const onboardingService = new OnboardingService()
