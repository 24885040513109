import { inject, injectable } from "inversify"
import { pluck } from "rxjs"
import { API_SERVICE_KEY } from "@global/di/keys"
import { ApiService } from "@global/di/services"
import { ISurveyDetail, ISurveyItem } from "@survey/interfaces"

@injectable()
export class SurveyApiService {
  @inject(API_SERVICE_KEY)
  private readonly apiService: ApiService

  /**
   * Возвращает список всех созданных опросников
   */
  getSurveyList$() {
    return this.apiService
      .get<{ data: { polls: Array<ISurveyItem> } }>("v1/poll/getPolls")
      .pipe(pluck("data", "polls"))
  }

  /**
   * Подробная информация по опроснику
   */
  getSurveyById$(surveyId: string) {
    return this.apiService
      .get<{ data: ISurveyDetail }>(`v1/Poll/GetPollDetails/${surveyId}`)
      .pipe(pluck("data"))
  }
}
