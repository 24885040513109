import { Instance, SnapshotIn, SnapshotOut, types as t } from "mobx-state-tree"
import { ColumnTypeBaseModel } from "@survey/models/ColumnTypeBase.model"

export const GroupReportCellTableBaseModel = ColumnTypeBaseModel.named(
  "GroupReportCellTableBaseModel"
).props({
  columnNumber: t.identifierNumber,
  value: t.string,
})

export type TGroupReportCellTableBaseModel = Instance<
  typeof GroupReportCellTableBaseModel
>
export type TGroupReportCellTableBaseModelSnapshotIn = SnapshotIn<
  typeof GroupReportCellTableBaseModel
>
export type TGroupReportCellTableBaseModelSnapshotOut = SnapshotOut<
  typeof GroupReportCellTableBaseModel
>
