import { createRouter, createWebHistory, RouteLocationRaw } from "vue-router"
import layoutRoutes from "@layout/router"
import matrixRoutes from "@matrix/router"
import surveyRoutes from "@survey/router"
import billingRoutes from "@billing/router"
import { authRoutes } from "@auth/router"
import { profileRoutes } from "@/profile/router"
import {
  setupAuthorizationRouterGuards,
  setupNeedAddOrganizationRouteGuard,
  setupRedirects,
} from "@global/utils/routeGuards"

export type TBreadcrumbs = Array<
  | {
      name: string
      to: RouteLocationRaw
      isCurrent: false
    }
  | {
      name: string
      isCurrent: true
    }
>

declare module "vue-router" {
  interface RouteMeta {
    breadcrumbs?: TBreadcrumbs
    pageToGoBackwards?: {
      path: string
      name: string
      params?: Record<string, string>
    } | null
  }
}

export const routes = [
  ...layoutRoutes,
  ...matrixRoutes,
  ...surveyRoutes,
  ...billingRoutes,
  ...authRoutes,
  ...profileRoutes,
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

setupRedirects(router)
setupAuthorizationRouterGuards(router)
setupNeedAddOrganizationRouteGuard(router)

export default router
