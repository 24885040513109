import { Instance, SnapshotIn, SnapshotOut } from "mobx-state-tree"
import { GroupReportCellTableBaseModel } from "@survey/models/GroupReportCellTableBase.model"

export const GroupReportSubnameModel = GroupReportCellTableBaseModel.named(
  "GroupReportSubnameModel"
)

export type TGroupReportSubnameModel = Instance<typeof GroupReportSubnameModel>
export type TGroupReportSubnameModelSnapshotIn = SnapshotIn<
  typeof GroupReportSubnameModel
>
export type TGroupReportSubnameModelSnapshotOut = SnapshotOut<
  typeof GroupReportSubnameModel
>
