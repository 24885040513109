export enum ColumnTypeEnum {
  UNKNOWN = 0,
  STATUS = 1,
  NAME = 2,
  GENERAL_RATING = 3,
  TARGET_LEVEL_OVER_PERCENT = 4,
  TARGET_LEVEL_PERCENT_WITH_WEIGHT = 5,
  GENERAL_LEVEL_PERCENT = 6,
  COMPETENCE_LEVEL_PERCENT = 7,
  DATE = 8,
  EXPERIENCE = 9,
}

export interface IColumn {
  columnType: ColumnTypeEnum
  groupName: string | null
  name: string
}

export interface IRow {
  // TODO устранить ошибку при доработке групповых отчетов для индекса лжи
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cells: any[]
  respondentId: string
  isVisible: boolean
}

export interface IGroupReportTable {
  columns: IColumn[]
  rows: IRow[]
  totalRatingsCount: number
}
