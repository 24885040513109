import { Instance, SnapshotIn, SnapshotOut, types as t } from "mobx-state-tree"

export const DynamicFormSettingsModel = t.model("DynamicFormSettingsModel", {
  isAnonymous: t.optional(t.boolean, false),
  isUserNameVisible: t.optional(t.boolean, true),
  isUserNameRequired: t.optional(t.boolean, true),
  isUserEmailVisible: t.optional(t.boolean, true),
  isUserEmailRequired: t.optional(t.boolean, true),
  isUserPhoneVisible: t.optional(t.boolean, true),
  isUserPhoneRequired: t.optional(t.boolean, false),
  isUserResumeUrlVisible: t.optional(t.boolean, true),
  isUserResumeUrlRequired: t.optional(t.boolean, false),
})

export type TDynamicFormSettingsModel = Instance<
  typeof DynamicFormSettingsModel
>
export type TDynamicFormSettingsModelSnapshotIn = SnapshotIn<
  typeof DynamicFormSettingsModel
>
export type TDynamicFormSettingsModelSnapshotOut = SnapshotOut<
  typeof DynamicFormSettingsModel
>
