import { ru } from "date-fns/locale"
import { format } from "date-fns"

export const dateFormattingToRussianStyle = (
  date: string | Date,
  type = "d MMM yyyy",
  options = {}
) => {
  if (typeof date === "string") {
    date = new Date(date)
  }

  return format(date, type, {
    locale: ru,
    ...options,
  })
}
