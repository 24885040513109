import { defineStore } from "pinia"
import { computed, ref } from "vue"
import { RouteLocationNormalized } from "vue-router"
import { PATHS } from "@global/utils/PATHS"
import { TAuthData } from "@auth/types/authTypes"
import { authService } from "@auth/services/authService"
import { organizationsService } from "@/organizations/services/organizationsService"
import { RoleTypes } from "@/organizations/types/organizations"

export const useAuthStore = defineStore("auth", () => {
  const router = ref()

  const user = ref({
    name: "",
    email: "",
    accountId: "",
    organizationName: "",
    organizationRole: 0,
    organizationId: "",
  })

  const isAuthorized = ref(false)
  const isMustRememberUser = ref(true)
  const redirectAfterLogin = ref<RouteLocationNormalized | string>()
  const isMemberOfOrganization = computed(() =>
    [RoleTypes.Member, RoleTypes.Administrator].includes(
      user.value.organizationRole
    )
  )
  const isAdminOfOrganization = computed(
    () => user.value.organizationRole === RoleTypes.Administrator
  )

  const pushToAuthFailed = () => {
    isAuthorized.value = false
    redirectAfterLogin.value = router.value.currentRoute.fullPath
    router.value.push(PATHS.authFailed)
  }

  const authData = ref<TAuthData | null>(null)

  const refreshToken = async () => {
    if (!authData.value) {
      removeAuthData()
      return
    }

    const newAuthData = await authService.refreshToken(
      authData.value.refreshToken
    )

    if (!newAuthData) {
      removeAuthData()
      return
    }

    setAuthData(newAuthData)
  }

  const refreshTokenTimeoutTimer = ref<ReturnType<typeof setTimeout>>()
  const setRefreshTokenTimeout = async () => {
    if (!authData.value) return

    const oneMinuteInMiliseconds = 60 * 1000
    const milisecondsLeft =
      new Date(authData.value.expiresAt).getTime() - new Date().getTime()

    if (milisecondsLeft < oneMinuteInMiliseconds) {
      await refreshToken()
      return
    }

    refreshTokenTimeoutTimer.value = setTimeout(
      refreshToken,
      milisecondsLeft - oneMinuteInMiliseconds
    )
  }

  const setAuthData = async (
    payload: TAuthData,
    saveToLocalStorage = false
  ) => {
    authData.value = payload

    if (isMustRememberUser.value || saveToLocalStorage) {
      localStorage.setItem("gt-access-token", authData.value.accessToken)
      localStorage.setItem("gt-expires-at", authData.value.expiresAt)
      localStorage.setItem("gt-refresh-token", authData.value.refreshToken)
    }

    isAuthorized.value = true
    await setRefreshTokenTimeout()
  }

  const removeAuthData = () => {
    authData.value = null
    localStorage.removeItem("gt-access-token")
    localStorage.removeItem("gt-expires-at")
    localStorage.removeItem("gt-refresh-token")
    isAuthorized.value = false
    clearInterval(refreshTokenTimeoutTimer.value)
  }

  const deleteUserOrganizationData = () => {
    user.value.organizationName = ""
    user.value.organizationRole = 0
    user.value.organizationId = ""
  }

  const getCurrentUser = async () => {
    const userResponse = await authService.getCurrentUser()

    if (!userResponse) {
      router.value.push(PATHS.authFailed)
      return
    }

    user.value.name = userResponse.name
    user.value.email = userResponse.email
    user.value.accountId = userResponse.account.id

    const organizationResponse =
      await organizationsService.getCurrentOrganization(userResponse.account.id)

    if (!organizationResponse) {
      deleteUserOrganizationData()
      return true
    }

    const currentOrganization = organizationResponse
      .filter((organization) => !organization.organization.isDeleted)
      .find((organization) => organization.isCurrent)

    if (!currentOrganization) {
      deleteUserOrganizationData()
      return true
    }

    user.value.organizationName = currentOrganization.organization.name
    user.value.organizationRole = currentOrganization.role
    user.value.organizationId = currentOrganization.organizationId

    return true
  }

  return {
    isAuthorized,
    isMemberOfOrganization,
    isAdminOfOrganization,
    redirectAfterLogin,
    pushToAuthFailed,
    router,
    setAuthData,
    removeAuthData,
    authData,
    getCurrentUser,
    user,
    isMustRememberUser,
  }
})

export type TAuthStore = ReturnType<typeof useAuthStore>
