<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_136_1320)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.99984 2.41675C5.81168 2.41675 2.4165 5.81192 2.4165 10.0001C2.4165 14.1882 5.81168 17.5834 9.99984 17.5834C14.188 17.5834 17.5832 14.1882 17.5832 10.0001C17.5832 5.81192 14.188 2.41675 9.99984 2.41675ZM0.916504 10.0001C0.916504 4.98349 4.98325 0.916748 9.99984 0.916748C15.0164 0.916748 19.0832 4.98349 19.0832 10.0001C19.0832 15.0167 15.0164 19.0834 9.99984 19.0834C4.98325 19.0834 0.916504 15.0167 0.916504 10.0001Z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10 5.91669C10.4142 5.91669 10.75 6.25247 10.75 6.66669V10C10.75 10.4142 10.4142 10.75 10 10.75C9.58579 10.75 9.25 10.4142 9.25 10V6.66669C9.25 6.25247 9.58579 5.91669 10 5.91669Z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.25 13.3333C9.25 12.9191 9.58579 12.5833 10 12.5833H10.0088C10.423 12.5833 10.7588 12.9191 10.7588 13.3333C10.7588 13.7475 10.423 14.0833 10.0088 14.0833H10C9.58579 14.0833 9.25 13.7475 9.25 13.3333Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_136_1320">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
