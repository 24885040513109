import { injectable } from "inversify"
import { createToast } from "mosha-vue-toastify"
import { Observable, of } from "rxjs"
import { h } from "vue"
import { isElementInDomAndHasText } from "@shared/utils/checkElementInDom"

import { ToastSuccess, ToastDanger } from "@shared/components/toast"

@injectable()
export class ToastService {
  private static createToast(content: any, settings: any) {
    const options = {
      position: "top-right",
      hideProgressBar: true,
      showCloseButton: false,
      swipeClose: true,
      ...settings,
    }

    return createToast(content, options)
  }

  showToastSuccess$(message: string) {
    return new Observable((subscriber) => {
      const component = h(ToastSuccess, { message })

      subscriber.next(
        ToastService.createToast(component, {
          toastBackgroundColor: "#169e6d",
        })
      )
    })
  }

  showToastDanger$(message: string) {
    if (!isElementInDomAndHasText(".toast-danger__text", message)) {
      return new Observable((subscriber) => {
        const component = h(ToastDanger, { message })

        subscriber.next(
          ToastService.createToast(component, {
            toastBackgroundColor: "#f04438",
          })
        )
      })
    }
    return of({})
  }
}
