import { inject, injectable } from "inversify"
import { pluck } from "rxjs"

import { API_SERVICE_KEY } from "@global/di/keys"
import { ApiService } from "@global/di/services"
import { IRespondent, IRespondentWrapper } from "@survey/interfaces"

@injectable()
export class RespondentApiService {
  @inject(API_SERVICE_KEY)
  private readonly apiService: ApiService

  getRespondentsList$(pollId: string) {
    return this.apiService
      .get<{ data: IRespondentWrapper }>(
        "/v1/Respondent/GetRespondentsList",
        {},
        {
          params: {
            pollId,
          },
        }
      )
      .pipe(pluck("data", "respondents"))
  }

  addRespondent$(
    userName = "",
    userEmail = "",
    userPhone = "",
    userResumeUrl: string | null,
    pollId = ""
  ) {
    return this.apiService
      .post<{ data: IRespondent }>("/v1/Respondent/AddRespondent", {
        params: {
          userName,
          userEmail,
          userPhone,
          userResumeUrl,
          pollId,
        },
      })
      .pipe(pluck("data"))
  }
}
