export type TOrganization = {
  id: string
  name: string
  inn: string
  kpp: string
  ogrn: string
  legalAddress: string
  postalAddress: string
  chiefName: string
  phone: string
  isDeleted: boolean
  createdAt: string
  members?: {
    accountId: string
    organizationId: string
    roleId: string
    organization: string
    role: RoleTypes
    createdAt: string
  }[]
}

export enum RoleTypes {
  Unknown,
  Administrator,
  Member,
}

export enum RequestStatus {
  Unknown,
  RequestStatus,
  Approved,
  Declined,
}

type TRequest = {
  id: string
  accountId: string
  organizationId: string
  status: RequestStatus
  createdAt: string
  acceptedAt: string
  declinedAt: string
  organization: TOrganization
}
export type TOrganizationJoinRequest = TRequest

export type TMember = {
  accountId: string
  organizationId: string
  roleId: string
  organization: TOrganization
  role: RoleTypes
  createdAt: string
}
