<template>
  <svg
    width="20"
    height="14"
    viewBox="0 0 20 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.916748 12.8333V13.5833H2.41675V12.8333H0.916748ZM10.9167 12.8333V13.5833H12.4167V12.8333H10.9167ZM2.41675 12.8333C2.41675 11.4142 3.58096 10.25 5.00008 10.25V8.75C2.75253 8.75 0.916748 10.5858 0.916748 12.8333H2.41675ZM5.00008 10.25H8.33341V8.75H5.00008V10.25ZM8.33341 10.25C9.75253 10.25 10.9167 11.4142 10.9167 12.8333H12.4167C12.4167 10.5858 10.581 8.75 8.33341 8.75V10.25Z"
      fill="currentColor"
    />
    <path
      d="M8.74998 1.9998C9.91665 3.16647 9.91665 4.9998 8.74998 6.08313C7.58331 7.16647 5.74998 7.2498 4.66665 6.08313C3.58331 4.91647 3.49998 3.16647 4.58331 1.9998C5.66665 0.833134 7.58331 0.916468 8.74998 1.9998"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.3335 8.6665H15.8335C17.2502 8.6665 18.3335 9.74984 18.3335 11.1665"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="square"
      stroke-linejoin="round"
    />
    <path
      d="M16.0833 2.58301C16.9167 3.41634 16.9167 4.74967 16.0833 5.49967C15.25 6.24967 13.9167 6.33301 13.1667 5.49967C12.4167 4.66634 12.3333 3.33301 13.1667 2.58301C13.9167 1.83301 15.25 1.83301 16.0833 2.58301"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "MatrixlistIcon",
}
</script>
