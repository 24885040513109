// Файл планируем удалить, нет смысла решать проблемы с типами
/* eslint-disable @typescript-eslint/no-explicit-any */

import { ContainerModule } from "inversify"
import { Store } from "vuex"
import { Router } from "vue-router"
import { App, reactive, shallowReactive } from "vue"

import { LayoutStore } from "@layout/store"
import * as keys from "./keys"
import * as services from "./services"
import container from "./container"

const provideContainers = (
  store: Store<any>,
  router: Router,
  app: App
): App => {
  const {
    STORE_KEY,
    LAYOUT_STORE_KEY,
    API_SERVICE_KEY,
    TOAST_SERVICE_KEY,
    CLIPBOARD_SERVICE_KEY,
    FILE_PRELOADER_SERVICE_KEY,
  } = keys

  const { ApiService, ToastService, ClipboardService, FilePreloaderService } =
    services
  /**
   * Создаем главный di-модуль с контейнером
   */
  const appContainerModule = new ContainerModule((bind) => {
    bind(LAYOUT_STORE_KEY).toFactory(() => LayoutStore.create() as any)
    bind(STORE_KEY).toFactory(() => store as any)
    bind(CLIPBOARD_SERVICE_KEY).to(ClipboardService)
    bind(API_SERVICE_KEY).to(ApiService)
    bind(TOAST_SERVICE_KEY).to(ToastService)
    bind(FILE_PRELOADER_SERVICE_KEY).toFactory(() => {
      const filePreloaderService = new FilePreloaderService()

      return shallowReactive(filePreloaderService) as any
    })
  })

  container.load(appContainerModule)

  /**
   * Выбираем ключи зарегистрированных состояний контейнера, которые нужно
   * внедрить в приложение.
   */
  const appServiceKeys: Array<symbol> = [
    LAYOUT_STORE_KEY,
    TOAST_SERVICE_KEY,
    CLIPBOARD_SERVICE_KEY,
    FILE_PRELOADER_SERVICE_KEY,
  ]

  /**
   * Добавляем состояния по ключам из контейнера.
   * Делаем проксирование каждого состояния через reactive.
   */
  appServiceKeys.forEach((key) => {
    let service = null

    switch (key) {
      case TOAST_SERVICE_KEY:
      case FILE_PRELOADER_SERVICE_KEY:
      case CLIPBOARD_SERVICE_KEY:
      case LAYOUT_STORE_KEY:
        service = reactive(container.get(key) as never)
        break
    }

    app.provide(key, service)
  })

  return app
}

export default provideContainers
