/**
 * Функция приведения даты к нужному часовому поясу
 * @param {string} stringUtcDate - строка с датой в формате iso со временем UTC+0
 * @param {boolean} moscowTime - если true, то нужно привести дату к московсому времени
 * @returns {object}
 */

export function getValidUtcDate(stringUtcDate: string, moscowTime = true) {
  const validDate = new Date(stringUtcDate)

  if (moscowTime) {
    validDate.setMinutes(validDate.getMinutes() + 180)
    return validDate
  }

  // Применяем унарный минус, чтобы поменять знак числа,
  // т.к., к примеру, для utc+3 (Московское время) метод возвращает -180.
  const timeOffset = -validDate.getTimezoneOffset()
  validDate.setMinutes(validDate.getMinutes() + timeOffset)

  return validDate
}
