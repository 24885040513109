import {
  getEnv,
  Instance,
  SnapshotIn,
  SnapshotOut,
  types as t,
} from "mobx-state-tree"
import { dateFormattingToRussianStyle } from "@survey/utils/dateFormattingToRussianStyle"
import { RespondentStatusesEnum } from "@survey/interfaces"

export const RespondentModel = t
  .model("RespondentModel", {
    id: t.identifier,
    isExpired: t.boolean,
    created: t.Date,
    pollLinkExpirationTime: t.maybeNull(t.Date),
    responseStatus: t.enumeration([
      RespondentStatusesEnum.UNKNOWN.toString(),
      RespondentStatusesEnum.CREATED.toString(),
      RespondentStatusesEnum.OPENED.toString(),
      RespondentStatusesEnum.IN_PROCESS.toString(),
      RespondentStatusesEnum.SUBMITTED.toString(),
      RespondentStatusesEnum.IN_CALCULATION.toString(),
      RespondentStatusesEnum.RATED.toString(),
    ]),
    name: t.string,
    email: t.maybeNull(t.string),
    phone: t.maybeNull(t.string),
    resumeLink: t.maybeNull(t.string),
    isVisible: t.boolean,
    isRead: t.boolean,
    isNew: t.maybeNull(t.boolean),
  })
  .views((self) => ({
    get formattedDateOfCreated(): string {
      return dateFormattingToRussianStyle(self.created)
    },

    get surveyLink(): string {
      const {
        environments: { VUE_APP_QUESTIONNAIRE_STAND },
      } = getEnv(self)

      return `${VUE_APP_QUESTIONNAIRE_STAND}poll-response/${self.id}`
    },

    get isSubmittedResponseStatus(): boolean {
      const status = Number(self.responseStatus)

      return status >= RespondentStatusesEnum.SUBMITTED
    },

    get isPollLinkHasExpired(): boolean {
      const { pollLinkExpirationTime } = self

      if (pollLinkExpirationTime) {
        const currentDateTime = new Date().getTime()

        return currentDateTime > pollLinkExpirationTime.getTime()
      }

      return false
    },
  }))
  .actions((self) => ({
    setToggleRead() {
      self.isRead = true
    },
  }))

export type TRespondentModel = Instance<typeof RespondentModel>
export type TRespondentModelSnapshotIn = SnapshotIn<typeof RespondentModel>
export type TRespondentModelSnapshotOut = SnapshotOut<typeof RespondentModel>
