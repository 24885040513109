import { Instance, SnapshotIn, SnapshotOut, types as t } from "mobx-state-tree"

import {
  GroupReportHeaderTableModel,
  TGroupReportHeaderTableModelModel,
} from "@survey/models/GroupReportHeaderTable.model"
import {
  GroupReportRowModel,
  TGroupReportRowModel,
} from "@survey/models/GroupReportRow.model"

export const GroupReportingTableModel = t
  .model("GroupReportingTableModel", {
    id: t.string,
    groupReportCols: t.map(GroupReportHeaderTableModel),
    groupReportRows: t.map(GroupReportRowModel),
    totalCounts: t.optional(t.integer, 0),
  })
  .views((self) => ({
    get isDataAvailable(): boolean {
      return self.groupReportRows.size > 0 && self.groupReportCols.size > 0
    },

    get allGroupReportCols(): TGroupReportHeaderTableModelModel[] {
      return [...self.groupReportCols.values()]
    },

    get allGroupReportRows(): TGroupReportRowModel[] {
      return [...self.groupReportRows.values()]
    },
  }))

export type TGroupReportingTableModel = Instance<
  typeof GroupReportingTableModel
>
export type TGroupReportingTableModelSnapshotIn = SnapshotIn<
  typeof GroupReportingTableModel
>
export type TGroupReportingTableModelSnapshotOut = SnapshotOut<
  typeof GroupReportingTableModel
>
