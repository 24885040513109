import {
  applySnapshot,
  Instance,
  SnapshotIn,
  SnapshotOut,
  types as t,
} from "mobx-state-tree"
import {
  SurveyItemModel,
  TSurveyItemModelSnapshotIn,
} from "@survey/models/SurveyItem.model"
import {
  FAKE_SURVEY_DETAIL_MODEL,
  SurveyDetailModel,
  TSurveyDetailModelSnapshotIn,
} from "@survey/models/SurveyDetail.model"

export const RootSurveyStoreModel = t
  .model({
    surveys: t.optional(t.array(SurveyItemModel), []),
    surveyDetail: t.optional(SurveyDetailModel, FAKE_SURVEY_DETAIL_MODEL),
  })
  .actions((self) => ({
    removeSurveyById(surveyId: string): void {
      const filteredArr = self.surveys.filter((item) => item.id !== surveyId)

      self.surveys.replace(filteredArr)
    },

    setSurveyDetail(modelSnapshotIn: TSurveyDetailModelSnapshotIn): void {
      applySnapshot(self.surveyDetail, modelSnapshotIn)
    },

    setSurveys(data: Array<TSurveyItemModelSnapshotIn>): void {
      applySnapshot(self.surveys, data)
    },
  }))

export type TRootSurveyStoreModel = Instance<typeof RootSurveyStoreModel>
export type TRootSurveyStoreModelSnapshotIn = SnapshotIn<
  typeof RootSurveyStoreModel
>
export type TRootSurveyStoreModelSnapshotOut = SnapshotOut<
  typeof RootSurveyStoreModel
>
