import { RouteRecordRaw } from "vue-router"
import { PATHS } from "@/global/utils/PATHS"

export const profileRoutes: Array<RouteRecordRaw> = [
  {
    ...PATHS.profile,
    component: () => import("@profile/views/ProfileView.vue"),
    meta: {
      pageToGoBackwards: PATHS.home,
      breadcrumbs: [
        {
          name: "Личные данные",
          isCurrent: true,
        },
      ],
    },
    redirect: PATHS.profileInfo,
    children: [
      {
        ...PATHS.profileInfo,
        component: () => import("@profile/views/ProfileInfoView.vue"),
        meta: {
          pageToGoBackwards: PATHS.home,
          breadcrumbs: [
            {
              name: "Личные данные",
              isCurrent: true,
            },
          ],
        },
      },
      {
        ...PATHS.profileConnectedAccounts,
        component: () =>
          import("@profile/views/ProfileConnectedAccountsView.vue"),
        meta: {
          pageToGoBackwards: PATHS.home,
          breadcrumbs: [
            {
              name: "Личные данные",
              isCurrent: true,
            },
          ],
        },
      },
      {
        ...PATHS.profileChangePassword,
        component: () => import("@profile/views/ProfileChangePasswordView.vue"),
        meta: {
          pageToGoBackwards: PATHS.home,
          breadcrumbs: [
            {
              name: "Личные данные",
              isCurrent: true,
            },
          ],
        },
      },
    ],
  },
]
