export enum RespondentStatusesEnum {
  UNKNOWN = 0,
  CREATED = 1,
  OPENED = 2,
  IN_PROCESS = 3,
  SUBMITTED = 4,
  IN_CALCULATION = 6,
  RATED = 10,
}

export interface IRespondentWrapper {
  respondents: Array<IRespondent>
}

export interface IRespondent {
  created: string
  id: string
  isExpired: boolean
  pollLinkExpirationTime: string
  responseStatus: RespondentStatusesEnum
  userEmail: string
  userName: string
  userPhone: string | null
  userResumeUrl: string | null
  isVisible: boolean
  isRead: boolean
  isNew: boolean
}
