import { Instance, SnapshotIn, SnapshotOut, types as t } from "mobx-state-tree"
import { dateFormattingToRussianStyle } from "@survey/utils/dateFormattingToRussianStyle"

export const SurveyItemModel = t
  .model("SurveyItemModel", {
    id: t.identifier,
    created: t.string,
    name: t.string,
    lastResponse: t.maybeNull(t.string),
    respondentsCount: t.integer,
    responsesCount: t.integer,
  })
  .views((self) => ({
    get formattedDateOfCreated() {
      return dateFormattingToRussianStyle(self.created || "")
    },

    get formattedDateLastResponse() {
      if (self.lastResponse) {
        return dateFormattingToRussianStyle(self.lastResponse)
      }

      return "Нет данных"
    },
  }))

export type TSurveyItemModel = Instance<typeof SurveyItemModel>
export type TSurveyItemModelSnapshotIn = SnapshotIn<typeof SurveyItemModel>
export type TSurveyItemModelSnapshotOut = SnapshotOut<typeof SurveyItemModel>
