import { Instance, SnapshotIn, SnapshotOut, types as t } from "mobx-state-tree"

export enum SidebarStateEnum {
  VISIBLE = "visible",
  INVISIBLE = "invisible",
}

export const LayoutStoreModel = t
  .model("LayoutStoreModel", {
    sidebarState: t.optional(
      t.enumeration([SidebarStateEnum.VISIBLE, SidebarStateEnum.INVISIBLE]),
      SidebarStateEnum.VISIBLE
    ),
  })
  .views((self) => ({
    get isVisibleSidebar(): boolean {
      return self.sidebarState === SidebarStateEnum.VISIBLE
    },
  }))
  .actions((self) => ({
    toggleSidebarState(): void {
      self.sidebarState =
        self.sidebarState === SidebarStateEnum.VISIBLE
          ? SidebarStateEnum.INVISIBLE
          : SidebarStateEnum.VISIBLE
    },
  }))

export type TLayoutStoreModel = Instance<typeof LayoutStoreModel>
export type TLayoutStoreModelSnapshotIn = SnapshotIn<typeof LayoutStoreModel>
export type TLayoutStoreModelSnapshotOut = SnapshotOut<typeof LayoutStoreModel>
