import { Instance, SnapshotIn, SnapshotOut, types as t } from "mobx-state-tree"
import { ColumnTypeEnum } from "@survey/interfaces"

export const ColumnTypeBaseModel = t
  .model("ColumnTypeBaseModel", {
    columnType: t.enumeration([
      ColumnTypeEnum.UNKNOWN.toString(),
      ColumnTypeEnum.STATUS.toString(),
      ColumnTypeEnum.NAME.toString(),
      ColumnTypeEnum.GENERAL_RATING.toString(),
      ColumnTypeEnum.TARGET_LEVEL_OVER_PERCENT.toString(),
      ColumnTypeEnum.TARGET_LEVEL_PERCENT_WITH_WEIGHT.toString(),
      ColumnTypeEnum.GENERAL_LEVEL_PERCENT.toString(),
      ColumnTypeEnum.COMPETENCE_LEVEL_PERCENT.toString(),
      ColumnTypeEnum.DATE.toString(),
      ColumnTypeEnum.EXPERIENCE.toString(),
    ]),
  })
  .views((self) => ({
    get isExperienceFieldColType(): boolean {
      return self.columnType === ColumnTypeEnum.EXPERIENCE.toString()
    },

    get isGeneralLevelPercentColType(): boolean {
      return self.columnType === ColumnTypeEnum.GENERAL_LEVEL_PERCENT.toString()
    },

    get isTargetLevelOverPercentColType(): boolean {
      return (
        self.columnType === ColumnTypeEnum.TARGET_LEVEL_OVER_PERCENT.toString()
      )
    },

    get isTargetLevelPercentWithWeightColType(): boolean {
      return (
        self.columnType ===
        ColumnTypeEnum.TARGET_LEVEL_PERCENT_WITH_WEIGHT.toString()
      )
    },

    get isCompetenceLevelPercentColType(): boolean {
      return (
        self.columnType === ColumnTypeEnum.COMPETENCE_LEVEL_PERCENT.toString()
      )
    },

    get isDateColType(): boolean {
      return self.columnType === ColumnTypeEnum.DATE.toString()
    },

    get isNameColType(): boolean {
      return self.columnType === ColumnTypeEnum.NAME.toString()
    },

    get isGeneralRatingColType(): boolean {
      return self.columnType === ColumnTypeEnum.GENERAL_RATING.toString()
    },
  }))

export type TColumnTypeBaseModel = Instance<typeof ColumnTypeBaseModel>
export type TColumnTypeBaseModelSnapshotIn = SnapshotIn<
  typeof ColumnTypeBaseModel
>
export type TColumnTypeBaseModelSnapshotOut = SnapshotOut<
  typeof ColumnTypeBaseModel
>
