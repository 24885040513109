import { inject, injectable } from "inversify"
import {
  forkJoin,
  from,
  NEVER,
  Subject,
  switchMap,
  tap,
  throwError,
  map,
  Observable,
  of,
  filter,
  retryWhen,
} from "rxjs"
import JSZip from "jszip"
import { saveAs } from "file-saver"

import {
  RESPONDENT_RATING_API_SERVICE,
  ROOT_SURVEY_STORE_KEY,
  FILE_PRELOADER_SERVICE_KEY,
  TOAST_SERVICE_KEY,
} from "@global/di/keys"
import {
  FilePreloaderService,
  RespondentRatingApiService,
  ToastService,
} from "@global/di/services"
import {
  TCompetenceModel,
  TRespondentModel,
  TRootSurveyStoreModel,
} from "@survey/models"
import { trimName } from "@survey/utils"

export enum ReportTypeEnum {
  GROUP_RATING_REPORT,
  PERSONAL_RATING_REPORT,
  PERSONAL_DEVELOPMENT_REPORT,
  GROUP_DEVELOPMENT_GENERAL_REPORT,
  GROUP_DEVELOPMENT_COMPETENCE_REPORT,
  IN_ONE_ARCHIVE,
}

export type TDownloadReport = {
  type: ReportTypeEnum
  model: TCompetenceModel | TRespondentModel | null
}

export type TFileReport = { file: Blob; fileName: string }

@injectable()
export class SurveyReportDownloadService {
  @inject(ROOT_SURVEY_STORE_KEY)
  private readonly surveyStore: TRootSurveyStoreModel

  @inject(TOAST_SERVICE_KEY)
  private readonly toastService: ToastService

  @inject(RESPONDENT_RATING_API_SERVICE)
  private readonly respondentRatingApiService: RespondentRatingApiService

  @inject(FILE_PRELOADER_SERVICE_KEY)
  private readonly filePreloaderService: FilePreloaderService

  public downloadReportSubject = new Subject<TDownloadReport>()

  private getGroupRatingReport$(): Observable<TFileReport> {
    return of(this.surveyStore.surveyDetail.id).pipe(
      filter((x) => x !== "0"),
      switchMap((pollId) =>
        this.respondentRatingApiService.getGroupRatingReport$(pollId, 1)
      ),
      map((res) => {
        const trimPollName = trimName(
          this.surveyStore.surveyDetail.pollName,
          100
        )

        return {
          file: res,
          fileName: `Групповой отчёт «${trimPollName}».xlsx`,
        }
      })
    )
  }

  private getGroupDevelopmentGeneralReport$(): Observable<TFileReport> {
    return of(this.surveyStore.surveyDetail.id).pipe(
      filter((x) => x !== "0"),
      switchMap((pollId) =>
        this.respondentRatingApiService.getGroupDevelopmentGeneralReport$(
          pollId,
          1
        )
      ),
      map((res) => {
        const trimPollName = trimName(
          this.surveyStore.surveyDetail.pollName,
          90
        )

        return {
          file: res,
          fileName: `Гр. отчёт развития МК «${trimPollName}».xlsx`,
        }
      })
    )
  }

  private getGroupDevelopmentCompetenceReport$(
    model: TCompetenceModel
  ): Observable<TFileReport> {
    return of(this.surveyStore.surveyDetail.id).pipe(
      filter((x) => x !== "0"),
      switchMap((pollId) =>
        this.respondentRatingApiService.getGroupDevelopmentCompetenceReport$(
          pollId,
          model.id,
          1
        )
      ),
      map((res) => {
        const trimPollName = trimName(
          this.surveyStore.surveyDetail.pollName,
          50
        )

        const trimCompetenceName = trimName(model.name, 50)

        return {
          file: res,
          fileName: `Групповой отчёт развития «${trimCompetenceName}» «${trimPollName}».xlsx`,
        }
      })
    )
  }

  private getAllReportsArchived$(): Observable<TFileReport> {
    return of(this.surveyStore.surveyDetail.allCompetences).pipe(
      map((res) =>
        (res || []).map((model) =>
          this.getGroupDevelopmentCompetenceReport$(model)
        )
      ),
      map((allCompetencesReqs$) => [
        this.getGroupRatingReport$(),
        this.getGroupDevelopmentGeneralReport$(),
        ...allCompetencesReqs$,
      ]),
      switchMap((reqs$) => forkJoin(reqs$)),
      map((res) =>
        res.reduce<JSZip>(
          (acc, item) => acc.file(item.fileName, item.file, { binary: true }),
          new JSZip()
        )
      ),
      switchMap((zip) => from(zip.generateAsync({ type: "blob" }))),
      map((res) => {
        const trimPollName = trimName(
          this.surveyStore.surveyDetail.pollName,
          100
        )

        return {
          file: res,
          fileName: `Групповые отчеты «${trimPollName}».zip`,
        }
      })
    )
  }

  private getPersonalRatingReport$(model: TRespondentModel) {
    return of(this.surveyStore.surveyDetail.id).pipe(
      filter((x) => x !== "0"),
      switchMap((pollId) =>
        this.respondentRatingApiService.getPersonalRatingReport$(
          pollId,
          model.id,
          1
        )
      ),
      map((res) => {
        const trimRespondentName = trimName(model.name, 100)

        return {
          file: res,
          fileName: `Отчёт «${trimRespondentName}».xlsx`,
        }
      })
    )
  }

  private getPersonalDevelopmentReport$(model: TRespondentModel) {
    return of(this.surveyStore.surveyDetail.id).pipe(
      filter((x) => x !== "0"),
      switchMap((pollId) =>
        this.respondentRatingApiService.getPersonalDevelopmentReport$(
          pollId,
          model.id,
          1
        )
      ),
      map((res) => {
        const trimRespondentName = trimName(model.name, 100)

        return {
          file: res,
          fileName: `Результаты «${trimRespondentName}».xlsx`,
        }
      })
    )
  }

  public downloadReportHandler$() {
    const event$ = this.downloadReportSubject.asObservable()

    let respondentModel: TRespondentModel

    return event$.pipe(
      tap(() => this.filePreloaderService.start()),
      tap((data) => {
        if (
          [
            ReportTypeEnum.PERSONAL_RATING_REPORT,
            ReportTypeEnum.PERSONAL_DEVELOPMENT_REPORT,
          ].includes(data.type) &&
          data.model
        ) {
          respondentModel = data.model as TRespondentModel
        }
      }),
      switchMap((data) => {
        switch (data.type) {
          case ReportTypeEnum.GROUP_RATING_REPORT: {
            return this.getGroupRatingReport$()
          }
          case ReportTypeEnum.GROUP_DEVELOPMENT_GENERAL_REPORT: {
            return this.getGroupDevelopmentGeneralReport$()
          }
          case ReportTypeEnum.GROUP_DEVELOPMENT_COMPETENCE_REPORT: {
            if (!data.model) {
              return throwError(() => new Error("Competence id is not known"))
            }

            return this.getGroupDevelopmentCompetenceReport$(data.model)
          }
          case ReportTypeEnum.IN_ONE_ARCHIVE: {
            return this.getAllReportsArchived$()
          }
          case ReportTypeEnum.PERSONAL_RATING_REPORT: {
            if (!data.model) {
              return throwError(
                () => new Error("Respondent model id is not known")
              )
            }

            return this.getPersonalRatingReport$(data.model as TRespondentModel)
          }
          case ReportTypeEnum.PERSONAL_DEVELOPMENT_REPORT: {
            if (!data.model) {
              return throwError(
                () => new Error("Respondent model id is not known")
              )
            }

            return this.getPersonalDevelopmentReport$(
              data.model as TRespondentModel
            )
          }
          default:
            return NEVER
        }
      }),
      tap({
        next: (res: TFileReport) => {
          saveAs(res.file, res.fileName)
          this.filePreloaderService.finish()
        },
      }),
      tap(() => {
        if (respondentModel) {
          respondentModel.setToggleRead()
        }
      }),
      retryWhen((errors) =>
        errors.pipe(
          tap(() => this.filePreloaderService.finish()),
          switchMap((err) => this.toastService.showToastDanger$(err.message))
        )
      )
    )
  }
}
