<template>
  <svg
    class="gt-loader"
    width="62"
    height="62"
    viewBox="0 0 62 62"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M62 31C62 48.1208 48.1208 62 31 62C13.8792 62 0 48.1208 0 31C0 13.8792 13.8792 0 31 0C48.1208 0 62 13.8792 62 31ZM9.64947 31C9.64947 42.7916 19.2084 52.3505 31 52.3505C42.7916 52.3505 52.3505 42.7916 52.3505 31C52.3505 19.2084 42.7916 9.64947 31 9.64947C19.2084 9.64947 9.64947 19.2084 9.64947 31Z"
      fill="var(--color-orange2)"
    />
    <path
      d="M55.0867 41.3636C57.5109 42.4066 60.3611 41.2904 61.0195 38.7348C61.9893 34.9712 62.2462 31.0445 61.7612 27.1598C61.0781 21.6879 58.9478 16.4972 55.5899 12.1231C52.232 7.74899 47.7682 4.34972 42.6586 2.27586C39.0311 0.803514 35.1714 0.0370459 31.285 0.00130013C28.6461 -0.0229716 26.8313 2.44199 27.2125 5.05333C27.5937 7.66466 30.0375 9.41635 32.6685 9.62171C34.8561 9.79245 37.0139 10.2987 39.0645 11.1309C42.5989 12.5655 45.6866 14.9168 48.0093 17.9425C50.332 20.9681 51.8056 24.5586 52.2781 28.3436C52.5523 30.5396 52.4837 32.755 52.0834 34.9123C51.6019 37.5071 52.6626 40.3206 55.0867 41.3636Z"
      fill="var(--color-orange3)"
    />
  </svg>
</template>

<style scoped lang="scss">
.gt-loader {
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
