import { ref, computed } from "vue"
import { defineStore } from "pinia"
import { getValidUtcDate } from "@billing/utils/getValidUtcDate"
import { format } from "date-fns"
import { ISubscription } from "../interfaces/billing.interface"
import { billingService } from "@billing/services/billingService"

const DATE_FORMAT = "dd.MM.yyyy"

const defaultTariff = {
  id: "",
  name: "",
  systemName: "",
  isIndividual: false,
  durationOption: { timeUnit: 0, quantity: 0, isUnlimited: false },
  priceOption: { currency: 0, price: 0, isFree: false },
  limitOption: { pollResponsesLimit: 0 },
  clientMatrixLimitOption: { limit: 0, isUnlimited: false },
  indicatorCopyingOption: { enabled: false },
  pdfExportOption: { enabled: false },
  discountOption: null,
  demoOption: { isDemo: false },
}

const defaultSubscription = {
  paidPeriodId: "",
  activated: "0001-01-01T00:00:00+00:00",
  expires: "0001-01-01T00:00:00+00:00",
  clientMatricesCreated: 0,
  tariff: defaultTariff,
  periodType: 0,
  subscriptionType: "",
  paymentProcessingDate: "",
  availableRespondentsCount: null,
  hiddenRespondentsCount: 0,
}

export const useSubscriptionsStore = defineStore("subscriptions", () => {
  const currentSubscription = ref<ISubscription>(defaultSubscription)
  const subscriptionsList = ref<ISubscription[]>([])

  const getSubscriptions = async () => {
    const res = await billingService.getSubscriptions()
    if (res) {
      subscriptionsList.value = res
    }
  }

  const currentTariffDateExpiresFormatted = computed((): string => {
    const validDate: Date = getValidUtcDate(currentSubscription.value?.expires)
    return format(validDate, DATE_FORMAT)
  })

  const currentTariffDateActivatedFormatted = computed((): string => {
    const validDate: Date = getValidUtcDate(currentSubscription.value.activated)
    return format(validDate, DATE_FORMAT)
  })

  const nextSubscription = computed((): ISubscription | null => {
    const NEXT_PERIOD_TYPE_ID = 3
    return (
      subscriptionsList.value?.find(
        (subscription) => subscription.periodType == NEXT_PERIOD_TYPE_ID
      ) ?? null
    )
  })

  const hasFuturePaidSubscription = computed((): boolean => {
    const isPaidSubscription: boolean =
      nextSubscription.value?.subscriptionType === "paid"
    const isFutureSubscription: boolean = nextSubscription.value
      ? Date.parse(nextSubscription.value?.expires) !==
        Date.parse(currentSubscription.value.expires)
      : false

    return isPaidSubscription && isFutureSubscription
  })

  // Для совместимости с компонентами, которые были завязаны на vuex
  const isCurrentSubscriptionFree = computed(
    () => currentSubscription.value.subscriptionType === "free"
  )
  const isCurrentSubscriptionDemo = computed(
    () => currentSubscription.value.subscriptionType === "demo"
  )
  const isCurrentSubscriptionPaid = computed(
    () => currentSubscription.value.subscriptionType === "paid"
  )

  const updateSubscriptionWithHeaders = (
    responseHeaders: Record<string, string>
  ) => {
    if (!responseHeaders) return

    const subscriptionTypeHeader = "x-gotalent-subscription-type"
    if (subscriptionTypeHeader in responseHeaders) {
      currentSubscription.value.subscriptionType =
        responseHeaders[subscriptionTypeHeader]
    }

    const clientMatrixLimitHeader = "x-gotalent-client-matrix-limit"
    if (clientMatrixLimitHeader in responseHeaders) {
      currentSubscription.value.tariff.clientMatrixLimitOption.limit = parseInt(
        responseHeaders[clientMatrixLimitHeader],
        10
      )
    }

    const pdfExportAllowedHeader = "x-gotalent-pdf-export-allowed"
    if (pdfExportAllowedHeader in responseHeaders) {
      currentSubscription.value.tariff.pdfExportOption.enabled =
        responseHeaders[pdfExportAllowedHeader].toLowerCase() === "true"
    }

    const indicatorCopyingAllowedHeader = "x-gotalent-indicator-copying-allowed"
    if (indicatorCopyingAllowedHeader in responseHeaders) {
      currentSubscription.value.tariff.indicatorCopyingOption.enabled =
        responseHeaders[indicatorCopyingAllowedHeader].toLowerCase() === "true"
    }

    const subscriptionExpiresHeader = "x-gotalent-subscription-expires"
    if (subscriptionExpiresHeader in responseHeaders) {
      currentSubscription.value.expires =
        responseHeaders[subscriptionExpiresHeader]
    }
  }

  const getCurrentSubscription = async () => {
    const currentSubscriptionResponse =
      await billingService.getCurrentSubscription()

    if (!currentSubscriptionResponse) return

    currentSubscription.value = currentSubscriptionResponse.data
  }

  return {
    subscriptionsList,
    getSubscriptions,
    currentTariffDateActivatedFormatted,
    currentTariffDateExpiresFormatted,
    nextSubscription,
    hasFuturePaidSubscription,
    currentSubscription,
    isCurrentSubscriptionFree,
    isCurrentSubscriptionDemo,
    isCurrentSubscriptionPaid,
    updateSubscriptionWithHeaders,
    getCurrentSubscription,
  }
})
