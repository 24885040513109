import { createStore } from "vuex"

import matrices from "@matrix/store"

const store = createStore({
  modules: {
    matrices,
  },
})

export default store
