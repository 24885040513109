export type TIndicatorLevel = {
  id: string
  level: number
  description: string
}

export type TIndicator = {
  id: string
  name: string
  indicatorLevels: TIndicatorLevel[]
}

export type TIndicatorGroup = {
  id: string
  name: string
  weight: number
  indicators: TIndicator[]
}

export type TCompetenceDetails = {
  id: string
  name: string
  description: string
  competenceLevels: TIndicatorLevel[]
  indicatorGroups: TIndicatorGroup[]
}

export interface TMatrices {
  id: string
  hasName: boolean
  name: string
  created: string
  updated: string
  competenceCount: number
  updatedDefault: string
  createdDefault: string
  disabled: boolean
}

export interface TGetUserMatrixList {
  matrices: TMatrices[]
  sorting: {
    currentSorting: number
  }
}

export interface TMatrixTemplate {
  id: string
  name: string
}

export type TCompetences = TCompetence[]
export type TCompetence = {
  id: string
  name: string
  description: string
  level: number
  levelDescription: string
  priority: number
  classSortOrder: number
  created: string
  disabled: boolean
  new: boolean
  groupId: string
  [priority: string]: string | number | boolean
}

export type TUserCompetenceList = TCompetenceList[]
export type TCompetenceList = {
  competences: TCompetence[]
  id: string
  name: string
}

export type TCompetenceForClusters = {
  id: string
  name: string
  groupId: string
}

export type TCompetenceClusters = {
  id: string
  name: string
  competences: TCompetenceForClusters[]
}

export type TCompetenceGroups = {
  clusters: TCompetenceClusters[]
  id: string
  name: string
  sortOrder: number
}

export type TAddCompetence = {
  id: string
  level: number
  priority: number
}

export type TOpenStatusMenu = {
  [key: string]: boolean
}

export type TCompetenceAll = {
  groups: TCompetenceGroups[]
}

export type TGroup = {
  id: string
  name: string
  competences: TCompetence[]
}

export type TTemplateMatrix = {
  id: string
  name: string
  hasName: boolean
  originalTemplateMatrixId: string
  originalTemplateMatrixName: string
  competenceSortCriterion: number
  created: string
  updated: string
  groups: TGroup[]
}

export enum CompetenceLevelEnum {
  NEWCOMER = 2,
  EXPERT = 3,
  PROFESSIONAL = 4,
  MASTER = 5,
}

export enum CompetencePriorityEnum {
  LOW = 1,
  REGULAR = 2,
  HIGH = 3,
}

export enum GroupTypeEnum {
  PROFESSIONAL = "Профессиональные",
  MANAGEMENT = "Управленческие",
  MOTIVATION = "Мотивационные",
  PERSONAL_BUSINESS = "Личностно-деловые",
}

export interface ICompetence {
  id: string
  name: string
  classSortOrder: number
  created: string
  description: string
  levelDescription: number | null
  level: CompetenceLevelEnum
  priority: CompetencePriorityEnum
}
