<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.5 12.9381C4.67675 11.7943 6.269 11.0908 8.01725 11.0908C9.74825 11.0908 11.3232 11.7801 12.5 12.9028C11.3232 14.0466 9.731 14.7501 7.98275 14.7501C6.25175 14.7501 4.67675 14.0608 3.5 12.9381Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1.98125 11.0188C1.52375 10.1083 1.25 9.089 1.25 8C1.25 4.2695 4.2695 1.25 8 1.25C11.7305 1.25 14.75 4.2695 14.75 8C14.75 9.0875 14.4778 10.1075 14.0173 11.0173"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="square"
      stroke-linejoin="round"
    />
    <path
      d="M9.59099 4.90901C10.4697 5.78769 10.4697 7.21231 9.59099 8.09099C8.71231 8.96967 7.28769 8.96967 6.40901 8.09099C5.53033 7.21231 5.53033 5.78769 6.40901 4.90901C7.28769 4.03033 8.71231 4.03033 9.59099 4.90901"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "ProfileIcon",
}
</script>
