// Файл планируем удалять, нет смысла решать проблему с типами
/* eslint-disable @typescript-eslint/no-explicit-any */

import { injectable } from "inversify"
import Axios from "axios-observable"
import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios"
import { catchError, Observable, of, pluck, switchMap, throwError } from "rxjs"

import { XHR401Error } from "./XHR401Error"
import { XHR400Error } from "./XHR400Error"
import { XHR404Error } from "./XHR404Error"
import { XHR402Error } from "./XHR402Error"
import { XHR403Error } from "./XHR403Error"
import { XHR415Error } from "./XHR415Error"
import { XHR422Error } from "./XHR422Error"
import { XHRNot200StatusError } from "./XHRNot200StatusError"
import { useSubscriptionsStore } from "@billing/store/subscriptions"
import { TAuthStore, useAuthStore } from "@auth/store/authStore"
import { authService } from "@auth/services/authService"

@injectable()
export class ApiService {
  static getTimezoneOffset() {
    const offset = new Date().getTimezoneOffset()
    const minutes = Math.abs(offset)

    return `${offset < 0 ? "" : "-"}${minutes}`
  }

  private instance
  private authStore: TAuthStore

  constructor() {
    this.instance = Axios.create({
      baseURL: process.env.VUE_APP_API || "/",
      headers: {
        "x-client-timezone": ApiService.getTimezoneOffset(),
      },
    })

    this.instance.interceptors.request.use(
      (requestConfig: AxiosRequestConfig) => {
        if (!this.authStore) {
          this.authStore = useAuthStore()
        }

        const accessToken = this.authStore.authData?.accessToken

        if (!requestConfig.headers || !accessToken) return requestConfig

        requestConfig.headers["Authorization"] = `Bearer ${accessToken}`

        return requestConfig
      }
    )

    this.createAxiosResponseInterceptor()

    // Извлекаем из ответа информацию о подписке
    this.instance.interceptors.response.use(
      this.extractSubscriptionInfo.bind(this)
    )
  }

  createAxiosResponseInterceptor() {
    const interceptor = this.instance.interceptors.response.use(
      (response) => response,
      async (error) => {
        if (
          error.response.status !== 401 ||
          error.response.data?.data?.message ===
            "The user does not have access to the requested resource"
        ) {
          return Promise.reject(error)
        }

        this.instance.interceptors.response.eject(interceptor)

        await authService
          .refreshToken(this.authStore.authData?.refreshToken ?? "")
          .then((authData) => {
            if (authData) {
              this.authStore.setAuthData(authData)
              window.location.reload()
            }
          })
          .catch((error) => {
            this.authStore.removeAuthData()
            this.authStore.pushToAuthFailed()
            return Promise.reject(error)
          })
          .finally(() => {
            this.createAxiosResponseInterceptor()
          })
      }
    )
  }

  /**
   * Извлекаем из ответа информацию об актуальной подписке и сохраняем в store.
   * @param {Object} response - объект ответа от сервера
   * @returns {Object} - объект ответа от сервера
   */
  private extractSubscriptionInfo(response: any) {
    if (response?.headers) {
      useSubscriptionsStore().updateSubscriptionWithHeaders(response.headers)
    }
    return response
  }

  /**
   * Обрабатывает ошибку сервера и возвращает observable с состоянием ошибки
   * @param {Object} error - объект ошибки от сервера
   * @returns {Object} - observable с состоянием ошибки
   */
  private serverErrorHandler(error: any) {
    const { status, statusText, data } = error.response

    const message = data?.data?.message

    const errorMessage = message?.length
      ? message
      : statusText?.length
      ? statusText
      : error.message

    switch (status) {
      case 400: {
        return throwError(() => new XHR400Error(errorMessage))
      }

      case 401: {
        return throwError(() => new XHR401Error(errorMessage))
      }

      case 402: {
        return throwError(() => new XHR402Error(errorMessage))
      }

      case 403: {
        return throwError(() => new XHR403Error(errorMessage))
      }

      case 404: {
        return throwError(() => new XHR404Error(errorMessage))
      }

      case 415: {
        return throwError(() => new XHR415Error(errorMessage))
      }

      case 422: {
        return throwError(() => new XHR422Error(errorMessage, data.data))
      }

      default: {
        return throwError(() => new Error(errorMessage))
      }
    }
  }

  /**
   * Определяет тип ошибки. Либо ошибка в успешном ответе запроса, либо ошибка на сервере
   * @param {Object} error - принимает объект ошибки
   * @returns {Object} - возвращает observable с ошибкой
   */
  private errorHandler(error: AxiosError) {
    if ((error.response as AxiosResponse)?.status >= 400) {
      return this.serverErrorHandler(error)
    }

    return throwError(() => error)
  }

  /**
   * Проверяет статус успешного ответа запроса и наличие ошибки от сервера
   * @param {Object} req - принимает observable запроса
   * @returns {Object} - возвращает либо успешный observable, либо observable с ошибкой
   */
  private withRequest(req: Observable<any>) {
    return req.pipe(
      switchMap((res: any) => {
        if (res?.status !== 200 && res?.statusText) {
          return throwError(() => new XHRNot200StatusError(res.statusText))
        }
        return of(res)
      }),
      pluck("data"),
      catchError(this.errorHandler.bind(this))
    )
  }

  /**
   * Формирует запрос на основе передаваемых параметров
   * @param {string} method - принимает тип метода запроса
   * @param {string} url - принимает точку запроса
   * @param {Object} data - принимает параметры запроса
   * @param {Object} config - дополнительные конфигурации запроса
   * @returns {Object} - возвращает либо успешный observable, либо observable с ошибкой
   */
  private request(method: string, url: string, data: object, config = {}) {
    const reqInfo = {
      method,
      url,
      data,
      ...config,
    } as AxiosRequestConfig

    return this.instance.request(reqInfo)
  }

  /**
   * Проверят ответ запроса на авторизацию.
   * @param {Object} req - observable запроса
   * @returns {Object} - возвращает либо успешный observable, либо observable с ошибкой
   */
  private checkAuth(req: Observable<any>) {
    return req.pipe(
      catchError((err) => {
        return throwError(err)
      })
    )
  }

  /**
   * GET
   * @param {string} endpoint - принимает точку запроса
   * @param {Object} data - принимает параметры запроса
   * @param {Object} config - принимает дополнительные конфигурации запроса
   * @returns {Object} - возвращает либо успешный observable, либо observable с ошибкой
   */
  get<T = never>(endpoint: string, data = {}, config = {}): Observable<T> {
    return this.checkAuth(
      this.withRequest(this.request("GET", endpoint, data, config))
    )
  }

  /**
   * POST
   * @param {string} endpoint - принимает точку запроса
   * @param {Object} data - принимает параметры запроса
   * @param {Object} config - принимает дополнительные конфигурации запроса
   * @returns {Object} - возвращает либо успешный observable, либо observable с ошибкой
   */
  post<T = never>(endpoint: string, data: object, config = {}): Observable<T> {
    return this.checkAuth(
      this.withRequest(this.request("POST", endpoint, data, config))
    )
  }

  /**
   * DELETE
   * @param {string} endpoint - принимает точку запроса
   * @param {Object} data - принимает параметры запроса
   * @param {Object} config - принимает дополнительные конфигурации запроса
   * @returns {Object} - возвращает либо успешный observable, либо observable с ошибкой
   */
  delete<T = never>(endpoint: string, data = {}, config = {}): Observable<T> {
    return this.checkAuth(
      this.withRequest(this.request("DELETE", endpoint, data, config))
    )
  }
}
