import { RouteRecordRaw } from "vue-router"
import { PATHS } from "@/global/utils/PATHS"

const routes: Array<RouteRecordRaw> = [
  {
    ...PATHS.tariffs,
    component: () => import("@billing/views/TariffsView.vue"),
    redirect: PATHS.tariffsSubscriptions,
    meta: {
      pageToGoBackwards: PATHS.home,
      breadcrumbs: [
        {
          name: "Тарифы",
          isCurrent: true,
        },
      ],
    },
    children: [
      {
        ...PATHS.tariffsSubscriptions,
        component: () => import("@billing/views/TariffsView.vue"),
        meta: {
          pageToGoBackwards: PATHS.home,
          breadcrumbs: [
            {
              name: "Тарифы",
              isCurrent: true,
            },
          ],
        },
      },
      {
        ...PATHS.tariffsPackages,
        component: () => import("@billing/views/TariffsView.vue"),
        meta: {
          pageToGoBackwards: PATHS.home,
          breadcrumbs: [
            {
              name: "Тарифы",
              isCurrent: true,
            },
          ],
        },
      },
    ],
  },
  {
    ...PATHS.tariffDetail,
    component: () => import("@billing/views/TariffDetailView.vue"),
    meta: {
      pageToGoBackwards: PATHS.tariffs,
      breadcrumbs: [
        {
          name: "Тарифы",
          to: { name: "tariffs" },
          isCurrent: false,
        },
      ],
    },
  },
  {
    ...PATHS.myCompanies,
    component: () => import("@/organizations/views/MyOrganizationsView.vue"),
    meta: {
      pageToGoBackwards: PATHS.home,
    },
    redirect: PATHS.myCompaniesList,
    children: [
      {
        ...PATHS.myCompaniesList,
        component: () =>
          import("@/organizations/components/MyOrganizationsList.vue"),
        meta: {
          pageToGoBackwards: PATHS.home,
          breadcrumbs: [
            {
              name: "Мои компании",
              isCurrent: true,
            },
          ],
        },
      },
      {
        ...PATHS.myCompaniesRequests,
        component: () =>
          import(
            "@/organizations/components/UserJoinOrganizationRequestsList.vue"
          ),
        meta: {
          pageToGoBackwards: PATHS.home,
          breadcrumbs: [
            {
              name: "Мои компании",
              isCurrent: true,
            },
          ],
        },
      },
    ],
  },
  {
    ...PATHS.companyDetail,
    component: () => import("@/organizations/views/OrganizationDetailView.vue"),
    props: true,
    meta: {
      pageToGoBackwards: PATHS.myCompanies,
      breadcrumbs: [
        {
          name: "Мои компании",
          isCurrent: false,
          to: PATHS.myCompaniesList,
        },
      ],
    },
    redirect: PATHS.companyDetailMembers,
    children: [
      {
        ...PATHS.companyDetailMembers,
        component: () =>
          import("@/organizations/components/OrganizationMembersList.vue"),
        meta: {
          pageToGoBackwards: PATHS.myCompanies,
          breadcrumbs: [
            {
              name: "Мои компании",
              isCurrent: false,
              to: PATHS.myCompaniesList,
            },
          ],
        },
      },
      {
        ...PATHS.companyDetailRequests,
        component: () =>
          import("@/organizations/components/JoinOrganizationRequestsList.vue"),
        meta: {
          pageToGoBackwards: PATHS.myCompanies,
          breadcrumbs: [
            {
              name: "Мои компании",
              isCurrent: false,
              to: PATHS.myCompaniesList,
            },
          ],
        },
      },
    ],
  },
  {
    ...PATHS.companyDetailEdit,
    component: () =>
      import("@/organizations/views/OrganizationDetailEditView.vue"),
    props: true,
    meta: {
      pageToGoBackwards: PATHS.myCompanies,
      breadcrumbs: [
        {
          name: "Мои компании",
          isCurrent: false,
          to: PATHS.myCompaniesList,
        },
      ],
    },
  },
  {
    ...PATHS.invitations,
    component: () => import("@/organizations/views/InvitationView.vue"),
    props: true,
    meta: {
      pageToGoBackwards: PATHS.myCompanies,
      breadcrumbs: [
        {
          name: "Мои компании",
          isCurrent: false,
          to: PATHS.myCompaniesList,
        },
      ],
    },
  },
]

export default routes
