export default {
  step1: {
    title: "Поздравляем! У вас 2 дня бесплатного демо-доступа!",
    description:
      "Вам доступны библиотека компетенций и каталог готовых моделей GoTalent. В рамках Демо-тарифа вы можете создать не более одной модели компетенций.",
  },
  step2: {
    title:
      "Управляйте вашими моделями компетенций в разделе «Мои\u00A0модели компетенций»",
    description:
      "Cоздавайте и изменяйте модели компетенций, скачивайте отчеты. Начните работу с сервисом с создания новой модели на основе библиотеки компетенций GoTalent.",
  },
  step3: {
    title:
      "Выбирайте готовые модели компетенций для должностей в\u00A0разделе «Каталог моделей»",
    description:
      "Ознакомьтесь со списком готовых моделей для отдела продаж. Добавьте модель в список «Мои модели компетенций» для\u00A0доработки под ваши задачи и дальнейшего использования.",
  },
}
