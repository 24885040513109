<template>
  <div class="onboarding" @click.self="closeOnboarding">
    <div class="onboarding__container">
      <button class="close-btn" type="button" @click="closeOnboarding">
        <CloseIconSmall class="close-icon" />
      </button>

      <div class="onboarding__top-content">
        <img
          :src="require(`@layout/assets/img/onboarding/step${currentStep}.svg`)"
          alt="onboarding step"
        />
      </div>

      <div class="onboarding__bottom-content">
        <div class="onboarding__title">
          {{ onboardingData[`step${currentStep}`].title }}
        </div>

        <div class="onboarding__description">
          {{ onboardingData[`step${currentStep}`].description }}
        </div>

        <div class="onboarding__buttons-container">
          <BaseButton class="onboarding__next-btn" @click="nextStep">
            Далее
          </BaseButton>
          <BaseButton btn-type="empty" @click="prevStep">
            {{ prevBtnText }}
          </BaseButton>

          <div class="onboarding__step-indicator-container">
            <div
              v-for="step in 3"
              :key="step"
              class="onboarding__step-indicator"
              :class="{
                'onboarding__step-indicator_active': step === currentStep,
              }"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import onboardingData from "./onboardingData"

import BaseButton from "@layout/components/BaseButton.vue"
import CloseIconSmall from "@layout/assets/icons/close-icon-small.vue"

import { useLayoutStore } from "@layout/store/layout"
import { mapActions } from "pinia"

export default {
  name: "Onboarding",

  components: {
    CloseIconSmall,
    BaseButton,
  },

  data: () => ({
    /**
     * Текущий шаг
     * @type {number}
     */
    currentStep: 1,

    /**
     * Данные для слайдов онбординга
     * @type {Object}
     */
    onboardingData: onboardingData,
  }),

  computed: {
    /**
     * Текст кнопки перехода к предыдущему шагу
     */
    prevBtnText() {
      return this.currentStep === 1 ? "Пропустить" : "Назад"
    },
  },

  methods: {
    /**
     * Переход к следующему шагу онбординга
     */
    nextStep() {
      if (this.currentStep === 3) {
        this.closeOnboarding()
      } else {
        this.currentStep++
      }
    },

    /**
     * Переход к предыдущему шагу онбординга
     */
    prevStep() {
      if (this.currentStep === 1) {
        this.closeOnboarding()
      } else {
        this.currentStep--
      }
    },

    ...mapActions(useLayoutStore, { closeOnboardingAction: "closeOnboarding" }),

    /**
     * Закрыть онбординг
     */
    async closeOnboarding() {
      await this.closeOnboardingAction()
    },
  },
}
</script>

<style lang="scss">
.onboarding {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: rgb(153 153 153 / 40%);

  .close-btn {
    position: absolute;
    top: 16px;
    right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    color: $color-white;
  }
}

.onboarding__container {
  position: relative;
  width: 569px;
  height: 502px;
}

.onboarding__top-content {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  height: 240px;
  background: $color-orange3;
}

.onboarding__bottom-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 240px);
  padding: 28px 40px 40px;
  background: $color-white;
}

.onboarding__title {
  @include font-b1;

  margin-bottom: 12px;
}

.onboarding__description {
  @include font-b5;

  margin-bottom: auto;
  color: $color-gray10;
}

.onboarding__buttons-container {
  display: flex;
  align-items: center;
}

.onboarding__next-btn {
  justify-content: center;
  width: 100px;
}

.onboarding__step-indicator-container {
  display: flex;
  gap: 20px;
  margin-left: auto;
}

.onboarding__step-indicator {
  width: 8px;
  height: 8px;
  background: #c4c4c4;
  border-radius: 50%;
}

.onboarding__step-indicator_active {
  background: $color-orange4;
}
</style>
