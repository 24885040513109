import { PATHS, PATHS_PUBLIC } from "@global/utils/PATHS"
import { Router } from "vue-router"
import { useAuthStore } from "@auth/store/authStore"

export function setupRedirects(router: Router) {
  router.beforeEach((to) => {
    if (to.path === "/matrix-list") return PATHS.userMatricesList
    if (to.path === "/subscription") return PATHS.tariffs
  })
}

export function setupAuthorizationRouterGuards(router: Router) {
  router.beforeEach((to) => {
    const userStore = useAuthStore()

    if (
      !userStore.isAuthorized &&
      !PATHS_PUBLIC.some((path) => to.path.includes(path))
    ) {
      userStore.redirectAfterLogin = to
      console.log("to login")
      return PATHS.login
    }

    if (
      userStore.isAuthorized &&
      PATHS_PUBLIC.some((path) => to.path.includes(path))
    ) {
      return PATHS.home
    }
  })
}

export function setupNeedAddOrganizationRouteGuard(router: Router) {
  router.beforeEach((to, from) => {
    const { isAuthorized, isMemberOfOrganization } = useAuthStore()
    const isFirstLoad = !from.redirectedFrom && !from.matched.length

    if (
      !isFirstLoad &&
      isAuthorized &&
      !isMemberOfOrganization &&
      !["/my-companies/list", "/my-companies/requests", "/"].includes(
        to.path
      ) &&
      !to.path.includes("/invitations")
    ) {
      return PATHS.home
    }
  })
}
