import { Instance, SnapshotIn, SnapshotOut, types as t } from "mobx-state-tree"

export const CompetenceModel = t.model("CompetenceModel", {
  id: t.identifier,
  name: t.string,
})

export type TCompetenceModel = Instance<typeof CompetenceModel>
export type TCompetenceModelSnapshotIn = SnapshotIn<typeof CompetenceModel>
export type TCompetenceModelSnapshotOut = SnapshotOut<typeof CompetenceModel>
