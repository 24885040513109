import {
  applySnapshot,
  getEnv,
  Instance,
  SnapshotIn,
  SnapshotOut,
  types as t,
} from "mobx-state-tree"
import { declOfNum } from "@survey/utils/declOfNum"
import {
  RespondentModel,
  TRespondentModel,
  TRespondentModelSnapshotIn,
} from "@survey/models/Respondent.model"
import {
  GroupReportingTableModel,
  TGroupReportingTableModelSnapshotIn,
} from "@survey/models/GroupReportingTable.model"
import {
  CompetenceModel,
  TCompetenceModel,
} from "@survey/models/Competence.model"
import { DynamicFormSettingsModel } from "@survey/models/DynamicFormSettings.model"
import { RespondentStatusesEnum } from "@survey/interfaces"

export const FAKE_SURVEY_DETAIL_MODEL: TSurveyDetailModelSnapshotIn = {
  id: "0",
  clientMatrixId: "",
  clientMatrixName: "",
  competences: {},
  defaultPollLinkLifetimeDays: 0,
  pollName: "",
  pollLinkExpirationTime: Date.now(),
}

export const SurveyDetailModel = DynamicFormSettingsModel.named(
  "SurveyDetailModel"
)
  .props({
    id: t.string,
    clientMatrixId: t.string,
    clientMatrixName: t.string,
    defaultPollLinkLifetimeDays: t.integer,
    pollName: t.string,
    respondents: t.map(RespondentModel),
    competences: t.map(CompetenceModel),
    groupReportingTable: t.optional(GroupReportingTableModel, { id: "0" }),
    pollLinkExpirationTime: t.Date,
  })
  .views((self) => ({
    get isUniversalLinkExpired(): boolean {
      const currentDateTime = new Date().getTime()
      const isPollLinkExpirationTimeNotNull = Boolean(
        self.pollLinkExpirationTime.getTime() !== new Date(0).getTime()
      )
      if (isPollLinkExpirationTimeNotNull) {
        return currentDateTime > self.pollLinkExpirationTime.getTime()
      }
      return false
    },

    get formFields(): string[] {
      const fields = []

      if (self.isUserNameVisible) {
        let fieldName = "ФИО"

        if (self.isUserNameRequired) {
          fieldName += "*"
        }

        fields.push(fieldName)
      }

      if (self.isUserPhoneVisible) {
        let fieldName = "Телефон"

        if (self.isUserPhoneRequired) {
          fieldName += "*"
        }

        fields.push(fieldName)
      }

      if (self.isUserEmailVisible) {
        let fieldName = "Email"

        if (self.isUserEmailRequired) {
          fieldName += "*"
        }

        fields.push(fieldName)
      }

      if (self.isUserResumeUrlVisible) {
        let fieldName = "Ссылка на резюме"

        if (self.isUserResumeUrlRequired) {
          fieldName += "*"
        }

        fields.push(fieldName)
      }

      return fields
    },

    get linkToPollModule(): string {
      const {
        environments: { VUE_APP_QUESTIONNAIRE_STAND },
      } = getEnv(self)

      return `${VUE_APP_QUESTIONNAIRE_STAND}survey/${self.id}/respondent-linking`
    },

    get allRespondents(): TRespondentModel[] {
      return [...self.respondents.values()]
    },

    get allCompetences(): TCompetenceModel[] {
      return [...self.competences.values()]
    },

    get pollLinkLifetimeDays() {
      if (typeof self.defaultPollLinkLifetimeDays === "number") {
        if (self.defaultPollLinkLifetimeDays === 0) {
          return "Бессрочно"
        }

        const dayText = declOfNum(self.defaultPollLinkLifetimeDays, [
          "день",
          "дня",
          "дней",
        ])

        return `${self.defaultPollLinkLifetimeDays} ${dayText}`
      }

      return ""
    },
  }))
  .views((self) => ({
    get respondentsSortedByDateOfCreation(): TRespondentModel[] {
      return self.allRespondents.sort(
        (a, b) => b.created.getTime() - a.created.getTime()
      )
    },
    get allRespondents(): TRespondentModel[] {
      return [...self.respondents.values()]
    },
  }))
  .views((self) => ({
    get isInsufficientRespondentViewLimit(): boolean {
      return self.allRespondents.some(
        (respondent: TRespondentModel) =>
          respondent.isVisible === false &&
          respondent.responseStatus === RespondentStatusesEnum.RATED.toString()
      )
    },

    get isIndefinite(): boolean {
      return self.defaultPollLinkLifetimeDays === 0
    },
  }))
  .actions((self) => ({
    setRespondents(data: Record<string, TRespondentModelSnapshotIn>): void {
      applySnapshot(self.respondents, data)
    },

    setGroupReportingTable(data: TGroupReportingTableModelSnapshotIn): void {
      applySnapshot(self.groupReportingTable, data)
    },

    deleteRespondent(id: string): void {
      self.respondents.delete(id)
    },

    addRespondent(respondent: TRespondentModel): void {
      self.respondents.set(respondent.id, respondent)
    },
  }))

export type TSurveyDetailModel = Instance<typeof SurveyDetailModel>
export type TSurveyDetailModelSnapshotIn = SnapshotIn<typeof SurveyDetailModel>
export type TSurveyDetailModelSnapshotOut = SnapshotOut<
  typeof SurveyDetailModel
>
