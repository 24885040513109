import { App } from "vue"
import { initYandexMetrika } from "yandex-metrika-vue3"
import { Router } from "vue-router"

export const addYandexMetrika = (app: App, router: Router) =>
  app.use(initYandexMetrika, {
    id: process.env.VUE_APP_YANDEX_METRIKA_ID,
    env: process.env.VUE_APP_ENV_MODE,
    router,
    options: {
      clickmap: true,
      trackLinks: true,
      trackHash: true,
      accurateTrackBounce: true,
      webvisor: true,
    },
  })
