<template>
  <div class="spiner">
    <LoadIcon class="spiner__icon" />
  </div>
</template>

<script>
import LoadIcon from "@layout/assets/icons/load-icon.vue"

export default {
  name: "Spinner",

  components: {
    LoadIcon,
  },
}
</script>

<style lang="scss">
.spiner {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: inherit;
  border-radius: inherit;
}

.spiner__icon {
  animation: loading 1s infinite linear;
}

@keyframes loading {
  to {
    transform: rotate(360deg);
  }
}
</style>
