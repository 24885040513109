import { injectable } from "inversify"

@injectable()
export class FilePreloaderService {
  visible = false

  start() {
    this.visible = true
  }

  finish() {
    this.visible = false
  }
}
