<template>
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.9 0.01005C13.2146 0.227256 16.6861 3.93712 16.4923 8.4H14.8938C15.0936 4.6 12.0277 1.6 8.50001 1.6C8.30667 1.6 8.11473 1.60901 7.92463 1.62667C7.4351 1.67356 6.96348 1.77498 6.51509 1.92413C5.56843 2.24052 4.70761 2.77621 4.00245 3.47644C2.95424 4.52019 2.26901 5.91066 2.12732 7.41771C2.10923 7.61007 2.1 7.80432 2.1 8C2.1 11.5276 5.1 14.5937 8.9 14.3938V15.9923C4.43712 16.1862 0.727261 12.7146 0.51005 8.4H0.507747C0.502242 8.27331 0.499691 8.14722 0.50003 8.0218C0.50001 8.01453 0.5 8.00727 0.5 8C0.5 5.81324 1.39822 3.81643 2.8499 2.36472C4.30284 0.904338 6.30582 3.18058e-10 8.50001 3.18058e-10C8.50731 3.18058e-10 8.51461 1.00127e-05 8.5219 3.00255e-05C8.64729 -0.00031006 8.77334 0.00223759 8.9 0.00773749V0.01005Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "LoadIcon",
}
</script>
