import { Instance, SnapshotIn, SnapshotOut, types as t } from "mobx-state-tree"
import {
  GroupReportCellModel,
  TGroupReportCellModel,
} from "@survey/models/GroupReportCell.model"
import { RespondentModel } from "@survey/models/Respondent.model"

export const GroupReportRowModel = t
  .model("GroupReportRowModel", {
    id: t.identifier,
    cells: t.array(GroupReportCellModel),
    respondentId: t.string,
    respondent: t.reference(RespondentModel),
    isVisible: t.boolean,
  })
  .views((self) => ({
    get nameCell(): string {
      const model = self.cells.find((cell) => cell.isNameColType)

      return model?.value || "Имя неизвестно"
    },

    get dateCell(): string {
      const model = self.cells.find((cell) => cell.isDateColType)

      return model?.value || "Дата неизвестна"
    },

    get targetLevelOverPercentCells(): TGroupReportCellModel[] {
      return self.cells
        .filter((x) => x.isTargetLevelOverPercentColType)
        .sort((a, b) => (a.columnNumber > b.columnNumber ? 1 : -1))
    },

    get targetLevelPercentWithWeightCells(): TGroupReportCellModel[] {
      return self.cells
        .filter((x) => x.isTargetLevelPercentWithWeightColType)
        .sort((a, b) => (a.columnNumber > b.columnNumber ? 1 : -1))
    },

    get experienceFieldCells(): TGroupReportCellModel[] {
      return self.cells
        .filter((x) => x.isExperienceFieldColType)
        .sort((a, b) => (a.columnNumber > b.columnNumber ? 1 : -1))
    },

    get generalLevelPercentCells(): TGroupReportCellModel[] {
      return self.cells
        .filter((x) => x.isGeneralLevelPercentColType)
        .sort((a, b) => (a.columnNumber > b.columnNumber ? 1 : -1))
    },

    get competenceLevelPercent(): TGroupReportCellModel[] {
      return self.cells
        .filter((x) => x.isCompetenceLevelPercentColType)
        .sort((a, b) => (a.columnNumber > b.columnNumber ? 1 : -1))
    },
  }))

export type TGroupReportRowModel = Instance<typeof GroupReportRowModel>
export type TGroupReportRowModelSnapshotIn = SnapshotIn<
  typeof GroupReportRowModel
>
export type TGroupReportRowModelSnapshotOut = SnapshotOut<
  typeof GroupReportRowModel
>
