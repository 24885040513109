<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.1569 3.51241C17.4976 3.7479 17.5829 4.21505 17.3475 4.5558L10.4531 14.5317L10.4531 14.5317C9.34812 16.1306 6.99576 16.1611 5.84968 14.5914L6.41241 14.1805L5.84968 14.5914L3.16434 10.9134C2.92009 10.5789 2.99328 10.1097 3.32782 9.86545C3.66235 9.6212 4.13155 9.69439 4.3758 10.0289L7.06114 13.7069C7.59837 14.4427 8.70112 14.4284 9.21916 13.6789C9.21916 13.6789 9.21917 13.6789 9.21917 13.6789L16.1135 3.703C16.349 3.36224 16.8161 3.27691 17.1569 3.51241Z"
    />
  </svg>
</template>
