import { RouteRecordRaw } from "vue-router"
import { PATHS } from "@global/utils/PATHS"

const routes: Array<RouteRecordRaw> = [
  {
    ...PATHS.survey,
    component: () => import("@survey/views/Survey.vue"),
    redirect: PATHS.surveyList,
    children: [
      {
        ...PATHS.surveyList,
        component: () => import("@survey/views/PollsListView.vue"),
        meta: {
          pageToGoBackwards: PATHS.home,
          breadcrumbs: [
            {
              name: "Опросники",
              isCurrent: true,
            },
          ],
        },
      },
      {
        ...PATHS.surveyCreate,
        component: () => import("@survey/views/PollCreateView.vue"),
        meta: {
          pageToGoBackwards: PATHS.surveyList,
          breadcrumbs: [
            {
              name: "Опросники",
              to: PATHS.surveyList,
              isCurrent: false,
            },
            {
              name: "Создание опросника",
              isCurrent: true,
            },
          ],
        },
      },
      {
        ...PATHS.addQuestion,
        component: () => import("@survey/views/AddQuestionView.vue"),
        meta: {
          pageToGoBackwards: PATHS.surveyCreate,
          breadcrumbs: [
            {
              name: "Опросники",
              to: PATHS.surveyList,
              isCurrent: false,
            },
            {
              name: "Создание опросника",
              isCurrent: false,
              to: PATHS.surveyCreate,
            },
            {
              name: "Добавление поля",
              isCurrent: true,
            },
          ],
        },
      },
      {
        ...PATHS.surveyDetail,
        component: () => import("@survey/views/SurveyDetail.vue"),
        redirect: PATHS.surveyInformation,
        children: [
          {
            ...PATHS.surveyInformation,
            component: () => import("@survey/views/PollDetailsView.vue"),
            meta: {
              pageToGoBackwards: PATHS.surveyList,
              breadcrumbs: [
                {
                  name: "Опросники",
                  to: PATHS.surveyList,
                  isCurrent: false,
                },
              ],
            },
          },
          {
            ...PATHS.surveyRespondents,
            component: () => import("@survey/views/PollRespondentsView.vue"),
            meta: {
              pageToGoBackwards: PATHS.surveyList,
              breadcrumbs: [
                {
                  name: "Опросники",
                  to: PATHS.surveyList,
                  isCurrent: false,
                },
              ],
            },
          },
          {
            ...PATHS.surveyResults,
            component: () => import("@survey/views/SurveyResults.vue"),
            meta: {
              pageToGoBackwards: PATHS.surveyList,
              breadcrumbs: [
                {
                  name: "Опросники",
                  to: PATHS.surveyList,
                  isCurrent: false,
                },
              ],
            },
          },
        ],
      },
      {
        ...PATHS.respondentDetails,
        component: () => import("@survey/views/RespondentDetailsView.vue"),
        meta: {
          pageToGoBackwards: PATHS.surveyList,
          breadcrumbs: [
            {
              name: "Опросники",
              to: PATHS.surveyList,
              isCurrent: false,
            },
          ],
        },
      },

      {
        ...PATHS.clonePoll,
        component: () => import("@survey/views/PollsListView.vue"),
        meta: {
          breadcrumbs: [
            {
              name: "Опросники",
              to: PATHS.surveyList,
              isCurrent: false,
            },
          ],
        },
      },
    ],
  },
]

export default routes
