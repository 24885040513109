import { RouteRecordRaw } from "vue-router"
import { PATHS } from "@/global/utils/PATHS"

const routes: Array<RouteRecordRaw> = [
  {
    ...PATHS.userMatrices,
    component: () => import("@matrix/views/UserMatricesLayout.vue"),
    children: [
      {
        ...PATHS.userMatricesList,
        component: () => import("@matrix/views/MatrixListView.vue"),
        meta: {
          pageToGoBackwards: PATHS.home,
        },
      },
      {
        ...PATHS.userMatrixDetail,
        component: () => import("@matrix/views/CompetenceMatrixView.vue"),
        meta: {
          pageToGoBackwards: PATHS.userMatricesList,
        },
      },
      {
        ...PATHS.userCompetenceDetail,
        component: () => import("@matrix/views/CompetenceContentView.vue"),
        meta: {
          pageToGoBackwards: PATHS.userMatrixDetail,
        },
      },
    ],
    redirect: PATHS.userMatricesList,
  },
  {
    ...PATHS.matrixTemplates,
    component: () => import("@matrix/views/CatalogTemplatesLayout.vue"),
    children: [
      {
        ...PATHS.matrixTemplateList,
        component: () => import("@matrix/views/MatrixTemplatesView.vue"),
        meta: {
          pageToGoBackwards: PATHS.home,
        },
      },
      {
        ...PATHS.matrixDetail,
        component: () => import("@matrix/views/CompetenceMatrixView.vue"),
        meta: {
          pageToGoBackwards: PATHS.matrixTemplateList,
        },
      },
      {
        ...PATHS.competenceDetail,
        component: () => import("@matrix/views/CompetenceContentView.vue"),
        meta: {
          pageToGoBackwards: PATHS.matrixDetail,
        },
      },
    ],
    redirect: PATHS.matrixTemplateList,
  },
]

export default routes
