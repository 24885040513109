import { persist } from "mst-persist"
import { first, from } from "rxjs"

import { LayoutStoreModel, TLayoutStoreModel } from "@layout/models"

export class LayoutStore {
  static create(): TLayoutStoreModel {
    const layoutStoreModel = LayoutStoreModel.create({})

    const persistHrLayoutStore$ = from(
      persist("hr-layout-store", layoutStoreModel, {
        whitelist: ["sidebarState"],
      })
    )

    persistHrLayoutStore$.pipe(first()).subscribe({
      error: () => console.error("hr-layout-store has not been hydrated"),
    })

    return layoutStoreModel
  }
}
