import "reflect-metadata"
import { createApp } from "vue"

import "mosha-vue-toastify/dist/style.css"
import "@global/assets/styles/index.scss"

import App from "./App.vue"
import router from "./router"
import store from "./global/store/store"

import { addPathsToVueApp } from "@global/utils/PATHS"
import provideContainers from "@global/di/provideContainers"
import { addAppEnvs } from "@global/di/appEnvs"
import { createPinia } from "pinia"
import FloatingVue, { options as floatingVueOptions } from "floating-vue"
import "floating-vue/dist/style.css"
import { GesturePlugin } from "@vueuse/gesture"
import VueDragscroll from "vue-dragscroll"
import Observer from "mobx-vue-lite"
import { addYandexMetrika } from "@plugins/yandex-metrika"

const pinia = createPinia()

const app = createApp(App)

app.use(pinia)
app.use(GesturePlugin)
provideContainers(store, router, app)
addPathsToVueApp(app)
addAppEnvs(app)
app.use(router)
app.use(store)
addYandexMetrika(app, router)
app.use(VueDragscroll)
// Проблема с типами в библиотеки. Возможно решаемая. В связи с курсом на полный отказ от mobx решать нецелесообразно.
// @ts-expect-error
app.use(Observer)

Object.assign(floatingVueOptions, {
  themes: {
    ...floatingVueOptions.themes,
    "gt-tooltip": {
      $extend: "tooltip",
      $resetCss: true,
      distance: 10,
    },
    "gt-tooltip-manual-showing": {
      $extend: "tooltip",
      $resetCss: true,
      distance: 10,
      triggers: [],
      hideTriggers: [],
    },
    "gt-tooltip-extended-width": {
      $extend: "tooltip",
      $resetCss: true,
      distance: 10,
    },
  },
})
app.use(FloatingVue)

app.config.unwrapInjectedRef = true

app.mount("#app")
