import { RouteRecordRaw } from "vue-router"
import { PATHS } from "@/global/utils/PATHS"

export const authRoutes: Array<RouteRecordRaw> = [
  {
    ...PATHS.login,
    component: () => import("@auth/views/LoginView.vue"),
    meta: {
      layout: "auth",
    },
  },
  {
    ...PATHS.forgotPassword,
    component: () => import("@auth/views/ForgotPasswordView.vue"),
    meta: {
      layout: "auth",
    },
  },
  {
    ...PATHS.resetPassword,
    component: () => import("@auth/views/ResetPasswordView.vue"),
    meta: {
      layout: "auth",
    },
  },
  {
    ...PATHS.register,
    component: () => import("@auth/views/RegisterView.vue"),
    meta: {
      layout: "auth",
    },
  },
  {
    ...PATHS.confirmEmail,
    component: () => import("@auth/views/ConfirmEmailView.vue"),
    meta: {
      layout: "auth",
    },
  },
  {
    ...PATHS.googleAuth,
    component: () => import("@auth/views/GoogleAuthView.vue"),
    meta: {
      layout: "auth",
    },
  },
  {
    ...PATHS.yandexAuth,
    component: () => import("@auth/views/YandexAuthView.vue"),
    meta: {
      layout: "auth",
    },
  },
  {
    ...PATHS.mailAuth,
    component: () => import("@auth/views/MailAuthView.vue"),
    meta: {
      layout: "auth",
    },
  },
  {
    ...PATHS.authFailed,
    component: () => import("@auth/views/AuthFailedView.vue"),
    meta: {
      layout: "auth",
    },
  },
]
