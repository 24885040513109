<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 11.82C13.961 11.501 14.946 11.298 16 11.18"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="square"
      stroke-linejoin="round"
    />
    <path
      d="M4 11.18C5.054 11.297 6.039 11.501 7 11.82"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="square"
      stroke-linejoin="round"
    />
    <path
      d="M16 7.18C14.946 7.297 13.961 7.501 13 7.82"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="square"
      stroke-linejoin="round"
    />
    <path
      d="M7 7.82C6.039 7.501 5.054 7.298 4 7.18"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="square"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10 4.13144C7.406 2.67849 5.088 2.10051 2.017 2.00151C1.459 1.98251 1 2.4425 1 3.00148V14.8541C1 15.3951 1.433 15.8441 1.974 15.8521C5.066 15.8981 7.394 16.468 10 18C12.606 16.468 14.934 15.8981 18.026 15.8521C18.567 15.8441 19 15.3951 19 14.8531V3.00048C19 2.4415 18.541 1.98251 17.983 2.00051C14.912 2.10051 12.594 2.67849 10 4.13144Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10 4V18"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "CatalogModelIcon",
}
</script>
