import { defineStore } from "pinia"
import { computed, ref } from "vue"
import { useSubscriptionsStore } from "@billing/store/subscriptions"
import { onboardingService } from "@layout/services/onboardingService"
import { OnboardingStatuses } from "@layout/types/onboarding"

export const useLayoutStore = defineStore("layout", () => {
  const subscriptionsStore = useSubscriptionsStore()

  const isGlobalLoaderActive = ref(false)
  const onboardingStatus = ref()

  const showOnboardingIfNeeded = async () => {
    const response = await onboardingService.getOnboardingStatus()

    if (!response) return

    onboardingStatus.value = response.data.onboardingStatus
  }

  const isOnboardingOpen = computed(() => {
    return (
      subscriptionsStore.isCurrentSubscriptionDemo &&
      [OnboardingStatuses.Unknown, OnboardingStatuses.InProcess].includes(
        onboardingStatus.value
      )
    )
  })

  const closeOnboarding = async () => {
    onboardingStatus.value = OnboardingStatuses.Ended
    await onboardingService.setOnboardingStatus(OnboardingStatuses.Ended)
  }

  return {
    onboardingStatus,
    isGlobalLoaderActive,
    showOnboardingIfNeeded,
    isOnboardingOpen,
    closeOnboarding,
  }
})
